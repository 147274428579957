import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/Component/Modal/Modal.css";
import Home from "../src/View/Home/Index";
import Login from "./Athentication/Login";

import MainLayout from "./Layout/MainLayout";
import PrivateRouter from "./PrivateRouter/PrivateRoute";
import Banner from "./Pages/Home/Banner";
import OurExperience from "./Pages/Home/OurExperience";
import Innovation from "./Pages/Home/Innovation";
import Achievements from "./Pages/Home/Achievements";
import Certification from "./Pages/Home/Certification";
import Carrer from "./Pages/Home/Carrer";
import Services from "./Pages/Home/Services";
import Testimonials from "./Pages/Home/Testimonials";
import OurStory from "./Pages/About/OurStory";
import OurMission from "./Pages/About/OurMission";
import Founders from "./Pages/About/Founders";
import Experts from "./Pages/About/Experts";
import Advisory from "./Pages/About/Advisory";
import AboutBanner from "./Pages/About/AboutBanner";
import CareerBanner from "./Pages/Career/Career";
import SolutionBanner from './Pages/Solutions/SolutionsBanner';
// import PharmaContent from "./Pages/Solutions/PharmaContents";
import WorkWithUs from "./Pages/Career/WorkWithUs";
import SideImage from "./Pages/Career/SideImage";
import PracticesBanner from "./Pages/Practices/Banner";
import Content from "./Pages/Practices/Content";
import EndDescription from "./Pages/Practices/EndDescription";
import CyberBanner from "./Pages/Practices/CyberBanner";
import CyberContent from "./Pages/Practices/CyberContent";
import CyberEndDescription from "./Pages/Practices/CyberEndDescription";
import FrameWorkBanner from "./Pages/FrameWork/FrameWorkBanner";
import FrameWorkContents from "./Pages/FrameWork/FrameWorkContents";
import FooterInfo from "./Pages/Footer/FooterInfo";
import Contact from "./Pages/Contact/Contact";
import ServicesBanner from "./Pages/Services/ServicesBanner";
import ServicesContent from "./Pages/Services/ServicesContent";
import ConsultantHeading from "./Pages/Services/ConsultantHeading";
import ConsultantDescription from "./Pages/Services/ConsultantDescription";
import ServicesEndDescription from "./Pages/Services/ServicesEndDescription";
// import SolutionEndDescription from "./Pages/Solutions/SolutionEndDescription";
import PharmaBanner from "./Pages/Solutions/Pharma/PharmaBanner";
import PharmaAllContents from "./Pages/Solutions/Pharma/PharmaAllContents";
import HttpClient from "./utils/HttpClient";
import PharmaEndDescription from "./Pages/Solutions/Pharma/PharmaEndDescription";
import DevelopersData from "./Pages/Career/DevelopersData";
// import AddNewSection from "./Pages/Solutions/AddNewSection";

const App = () => {
  const [solutionItems, setSolutionItems] = useState([]);
  const [servicesItems, setServicesItems] = useState([]);

  // >>>>Fetch Services Data<<<<
  const fetchServicesData = async () => {
    // setLoading(true);
    const res = await HttpClient.requestData("service", "GET");
    if (res && res?.status) {
      // setLoading(false);
      // console.log('res?.data: ', res?.data);
      setServicesItems(res?.data || []);
    } else {
      // setLoading(false);
      setServicesItems([]);
    }
  }

  // >>>>Fetch Solution Data<<<<
  const fetchSolutionData = async () => {
    // setLoading(true);
    const res = await HttpClient.requestData("solution", "GET");
    if (res && res?.status) {
      // setLoading(false);
      // console.log('res?.data: ', res?.data);
      setSolutionItems(res?.data || []);
    } else {
      // setLoading(false);
      setSolutionItems([]);
    }
  }
  useEffect(() => {
    fetchSolutionData();
    fetchServicesData();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRouter />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Home />} />

              {/* HOME Routes */}
              <Route path="/banner" element={<Banner />} />
              <Route path="/our-experience" element={<OurExperience />} />
              <Route path="/innovation" element={<Innovation />} />
              <Route path="/achievements" element={<Achievements />} />
              <Route path="/certification" element={<Certification />} />
              <Route path="/services" element={<Services />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/carrer" element={<Carrer />} />

              {/* About */}
              <Route path="/about-banner" element={<AboutBanner />} />
              <Route path="/our-story" element={<OurStory />} />
              <Route path="/our-mission" element={<OurMission />} />
              <Route path="/our-founders" element={<Founders />} />
              <Route path="/our-experts" element={<Experts />} />
              <Route path="/our-advisory" element={<Advisory />} />

              {/* Career */}
              <Route path="/career-banner" element={<CareerBanner />} />
              <Route path="/work-with-us" element={<WorkWithUs />} />
              <Route path="/career-side-image" element={<SideImage />} />
              <Route path="/developers-data" element={<DevelopersData />} />

              {/* Practices */}
              <Route path="/practices-banner" element={<PracticesBanner />} />
              <Route path="/practices-content" element={<Content />} />
              <Route path="/practices-end-description" element={<EndDescription />} />
              <Route path="/cyber-banner" element={<CyberBanner />} />
              <Route path="/cyber-content" element={<CyberContent />} />
              <Route path="/cyber-end-description" element={<CyberEndDescription />} />

              {/* Solutions */}
              {/* <Route path="/solution-banner" element={<SolutionBanner />} /> */}
              <Route path="/addNewSection" element={<SolutionBanner />} />
              {
                solutionItems?.length !== 0 && solutionItems?.map((solutionItem, index) => (
                  <React.Fragment key={index}>
                    <Route path={`/${solutionItem?.title}Banner/:id/:name`} element={<PharmaBanner />} />
                    <Route path={`/${solutionItem?.title}Contents/:id/:name`} element={<PharmaAllContents />} />
                    <Route path={`/${solutionItem?.title}EndDescription/:id/:name`} element={<PharmaEndDescription />} />
                  </React.Fragment>
                ))
              }

              {/* <Route path="/pharma-content" element={<PharmaContent />} />
              <Route path="/solution-end-description" element={<SolutionEndDescription />} /> */}

              {/* Framework */}
              <Route path="/framework-banner" element={<FrameWorkBanner />} />
              <Route path="/framework-contents" element={<FrameWorkContents />} />

              {/* Contact */}
              <Route path="/contact-us" element={<Contact />} />

              {/* Footer */}
              <Route path="/footer-info" element={<FooterInfo />} />

              {/* Services */}
              {
                servicesItems?.map((serviceItem, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Route path={`/${serviceItem?.title?.slice(0, 2).toLowerCase()}ServicesBanner/:id/:name`} element={<ServicesBanner />} />
                      <Route path={`/${serviceItem?.title?.slice(0, 2).toLowerCase()}ServicesContents/:id/:name`} element={<ServicesContent />} />
                      <Route path={`/${serviceItem?.title?.slice(0, 2).toLowerCase()}consultantHeading/:id/:name`} element={<ConsultantHeading />} />
                      <Route path={`/${serviceItem?.title?.slice(0, 2).toLowerCase()}ConsultantDescription/:id/:name`} element={<ConsultantDescription />} />
                    </React.Fragment>
                  );
                })
              }

              <Route path="/endDescription" element={<ServicesEndDescription />} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
