import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';


const ServicesEndDescription = () => {
    const bannerInit = {
        conslutionDescription: '',
        conslutionHeading: '',
        bannerId: ''
    };
    const [bannerHeadData, setBannerHeadData] = useState(bannerInit);
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    // const [bannerID, setBannerId] = useState("");

    // Handle Banner Head Input Change
    const handleBannerHeadChange = (e) => {
        const { name, value } = e.target;
        setBannerHeadData({ ...bannerHeadData, [name]: value });
    }


    // Fetch Banner Data
    const fetchBannerData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("service", "GET");
        if (res && res?.status) {
            setLoading(false);
            setBannerData(res.data ? res.data : []);
        } else {
            setLoading(false);
            setBannerData([]);
        }
    }

    // Handle Edit
    const handleEdit = bannerItem => {
        setIsEdit(true)
        setBannerHeadData({
            conslutionDescription: bannerItem.conslutionDescription || '',
            conslutionHeading: bannerItem.conslutionHeading || '',
            bannerId: bannerItem._id || ''
        });
    };

    // Toggle Modal
    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = bannerItem => {
        setBannerHeadData({
            conslutionDescription: bannerItem.conslutionDescription || '',
            conslutionHeading: bannerItem.conslutionHeading || ''
        });
        toggleModal();
    }

    // Handle Submit Data
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            conslutionDescription: bannerHeadData?.conslutionDescription,
            conslutionHeading: bannerHeadData?.conslutionHeading,
        };
        setLoading(true);
        const res = await HttpClient.requestData(`service/${bannerHeadData?.bannerId}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false);
            toast.success(res?.message || "Data updated successfully!");
            setBannerHeadData(bannerInit);
            fetchBannerData()
            setIsEdit(false)
        } else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong...");
        }
    }

    useEffect(() => {
        fetchBannerData();
    }, []);

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* Banner */}
                    {isEdit &&
                        <>
                            <div>
                                <h2>Update Consultant Description</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="heading">Consultant Heading</label>
                                                <input
                                                    type="text"
                                                    id="heading"
                                                    name="conslutionHeading"
                                                    className="form-control"
                                                    value={bannerHeadData?.conslutionHeading}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="description">Consultant Description</label>
                                                <input
                                                    type="text"
                                                    id="description"
                                                    name="conslutionDescription"
                                                    className="form-control"
                                                    value={bannerHeadData?.conslutionDescription}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                    </div>
                                </form>
                            </div>

                            <hr />
                        </>
                    }

                    {/* Custom Table to Display Banner Data */}
                    {bannerData && !isEdit && (
                        <div className="mt-5">
                            <h3>Consultant Description Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Consultant Heading</th>
                                        <th>Consultant Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bannerData && bannerData?.length !== 0 && bannerData?.map((bannerItem, index) => (
                                            <tr key={index}>
                                                <td>{bannerItem.conslutionHeading || '-'}</td>
                                                <td>{bannerItem.conslutionDescription || '-'}</td>

                                                <td>
                                                    <FaEye
                                                        style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                        onClick={() => handleViewDetails(bannerItem)}
                                                        title="View Details"
                                                    />
                                                    <FaEdit
                                                        style={{ cursor: "pointer", color: "#28a745" }}
                                                        onClick={() => handleEdit(bannerItem)}
                                                        title="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Consultant Description
                            <span style={{
                                marginLeft: "305px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Consultant Description</td>
                                        <td>{bannerHeadData.conslutionHeading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Consultant Description</td>
                                        <td>{bannerHeadData.conslutionDescription || '-'}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default ServicesEndDescription