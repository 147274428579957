import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import HttpClient from '../../../utils/HttpClient';
import Loader from '../../../Loader/Loader';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';

const PharmaEndDescription = () => {
    const { id, name } = useParams();
    const bannerInit = {
        endDescription: "",
        endHeading: "",
        bannerId: ""
    };
    const [bannerHeadData, setBannerHeadData] = useState(bannerInit);
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    // const [bannerID, setBannerId] = useState("")


    // Toggle Modal
    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = bannerItem => {
        setBannerHeadData({
            endDescription: bannerItem?.endDescription || "",
            endHeading: bannerItem?.endHeading || ""
        });
        toggleModal();
    }

    // Handle Edit
    const handleEdit = bannerItem => {
        setIsEdit(true)
        setBannerHeadData({
            endDescription: bannerItem?.endDescription || '',
            endHeading: bannerItem?.endHeading || '',
            bannerId: bannerItem?._id || '',
        });
    };



    // Handle Banner Head Input Change
    const handleBannerHeadChange = (e) => {
        const { name, value } = e.target;
        setBannerHeadData({ ...bannerHeadData, [name]: value });
    }

    // >>>>Fetch About Banner Data<<<<
    const fetchBannerData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("solution", "GET");
        if (res && res?.status) {
            setLoading(false);
            // setBannerId(res?.data?._id)
            setBannerData(res.data ? res?.data.find(item => item?._id === id) : []);
        } else {
            setLoading(false);
            setBannerData([]);
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            endDescription: bannerHeadData?.endDescription,
            endHeading: bannerHeadData?.endHeading,
        };

        setLoading(true);
        const res = await HttpClient.requestData(`solution/${bannerHeadData.bannerId}`, "PUT", data);

        if (res && res?.status) {
            setLoading(false);
            toast.success(res?.message || "Data updated successfully!");

            // Reset form and image field
            setBannerHeadData(bannerInit); // Reset form state

            setIsEdit(false); // Exit edit mode
            fetchBannerData(); // Fetch the updated data
        } else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong...!");
        }
    };

    useEffect(() => {
        fetchBannerData();
    }, [id, name]);

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {isEdit &&
                        <>
                            <div>
                                <h2>Update {name} End Description</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="endHeading">End Heading</label>
                                                <input
                                                    type="text"
                                                    id="endHeading"
                                                    name="endHeading"
                                                    className="form-control"
                                                    value={bannerHeadData?.endHeading}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="endDescription">End Description</label>
                                                <input
                                                    type="text"
                                                    id="endDescription"
                                                    name="endDescription"
                                                    className="form-control"
                                                    value={bannerHeadData?.endDescription}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                    </div>
                                </form>
                            </div>

                            <hr />
                        </>
                    }
                    {/* Custom Table to Display Banner Data */}
                    {bannerData && !isEdit && (
                        <div className="mt-5">
                            <h3>{name} Description Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>End Heading</th>
                                        <th>End Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bannerData.endHeading || '-'}</td>
                                        <td>{bannerData.endDescription || '-'}</td>
                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={() => handleViewDetails(bannerData)}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={() => handleEdit(bannerData)}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>End Description Details
                            <span style={{
                                marginLeft: "305px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>End Heading</td>
                                        <td>{bannerHeadData?.endHeading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>End Description</td>
                                        <td>{bannerHeadData?.endDescription || '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default PharmaEndDescription