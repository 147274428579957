import React, { useEffect, useState } from 'react'
import Loader from '../../Loader/Loader'
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import { TbCircleMinus } from 'react-icons/tb';
import { FiPlusCircle } from 'react-icons/fi';

const FrameWorkContents = () => {
    const contentInit = {
        dataHeading: "",
        dataDescription: "",
        dataImage: ""
    }

    const [contentsData, setContentsData] = useState([contentInit])
    const [loading, setLoading] = useState(false);

    const [contentsGetData, setContentsGetData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [contentsID, setContentsId] = useState("")
    const [viewData, setViewData] = useState("")
    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = (data) => {
        toggleModal();
        if (data) {
            setViewData(data)
        } else {
            setViewData("")
        }
    }


    // Handle Edit
    const handleEdit = () => {
        setIsEdit(true);
        setContentsData(contentsGetData?.map(service => ({
            dataHeading: service?.dataHeading || '',
            dataDescription: service?.dataDescription || "",
            dataImage: service?.dataImage || ''
        })));
        // setContentsData(res.push({
        //     heading: '',
        //     description: "",
        //     image: ''
        // }));
    };

    // >>>>Handle Incrementing Contents Input<<<<
    const handleAddContents = () => {
        setContentsData(prev => [...prev, { ...contentInit }]);
    };

    // const handleAddContents = () => {
    //     setContentsData(prev => [{ ...contentInit }, ...prev]);
    // };

    // >>>>Handle Decrementing Founders Input<<<<
    const handleRemoveContents = (index) => {
        setContentsData(prev => prev.filter((_, i) => i !== index));
    };

    // >>>>Handle Contents Input Change<<<<
    const handleFoundersChange = (index, field, value) => {
        // setContentsGetData(prev => {
        //     const updatedTestimonial = [...prev];
        //     updatedTestimonial[index] = { ...updatedTestimonial[index], [field]: value };
        //     return updatedTestimonial;
        // });
        setContentsData(prev => {
            const updatedContent = [...prev];
            updatedContent[index] = { ...updatedContent[index], [field]: value };
            return updatedContent;
        });
    };

    // >>>>Handle Image Upload<<<<
    // const handleImageChange = async (index, e) => {
    //     const file = e.target.files[0];
    //     if (!file) return;

    //     if (file.size > 5 * 1024 * 1024) {
    //         toast.error("Image size must be less than 5 MB");
    //         return;
    //     }

    //     let formData = new FormData();
    //     formData.append("dataImage", file);
    //     setLoading(true)
    //     const res = await HttpClient.fileUplode("image-upload", "POST", formData);
    //     console.log('image res: ', res);
        
    //     if (res && res.status) {
    //         setLoading(false)
    //         toast.success("Image Uploaded Successfully");
    //         handleFoundersChange(index, 'dataImage', res?.url);
    //     } else {
    //         setLoading(false)
    //         toast.error(res?.message || "Something went wrong");
    //     }
    // };

    const handleImageChange = async (index, e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("dataImage", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        console.log('res img: ', res);
        
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            handleFoundersChange(index, 'dataImage', res?.url);
        } else {
            setLoading(false)
            toast.error(res?.message || "Something went wrong");
        }
    };

    // >>>>Handle Removing Image<<<<
    const handleRemoveImage = (index) => {
        handleFoundersChange(index, 'dataImage', "");
        document.getElementById(`image-${index}`).value = "";
    };

    // >>>>Fetch Contents Data<<<<
    const fetchContentsData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("framework", "GET");
        if (res && res?.status) {
            setLoading(false);
            // console.log('res?.data: ', res?.data);

            setContentsId(res?.data[0]?._id);
            let contents = [];
            res.data.forEach(item => contents.push(item.content));
            contents = contents.flat(1);
            // setContentsGetData(res.data ? res?.data[0]?.content : []);
            setContentsGetData(res.data ? contents : []);
        } else {
            setLoading(false);
            setContentsGetData([]);
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            content: contentsData,
        }
        console.log("contentsID", contentsID);

        // return
        setLoading(true)
        const res = await HttpClient.requestData(`framework/${contentsID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setContentsData([contentInit])
            setIsEdit(false)
            fetchContentsData()
            document.querySelectorAll('input[type="file"]').forEach(input => input.value = '');
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!");
        }
    }

    useEffect(() => {
        fetchContentsData();
    }, [])
    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* Founders */}
                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <div className='d-flex'>
                                        <h2 >Update Framework Contents</h2> <span className='mt-2 mx-2' style={{ cursor: "pointer", color: "green" }} onClick={handleAddContents}><FiPlusCircle /> Add More Framework Contents</span>
                                    </div>
                                    <div style={{ height: "450px", overflow: "auto" }}>
                                        {contentsData && contentsData?.map((ele, index) => (
                                            <div className="form-group section p-2 mt-2" style={{ border: "1px solid #ccc" }}>
                                                <div className="form-group mb-3">
                                                    {index !== 0 && (
                                                        <span

                                                            onClick={() => handleRemoveContents(index)}
                                                        >
                                                            <TbCircleMinus style={{ cursor: "pointer", color: "red", marginTop: "-3px", marginRight: "2px" }} />
                                                        </span>
                                                    )}
                                                    <label htmlFor="heading2">Data Heading</label>
                                                    <input type="text" id="heading2" name="dataHeading" className="form-control" value={ele?.dataHeading} onChange={(e) => handleFoundersChange(index, 'dataHeading', e.target.value)} />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="description2">Data Description</label>
                                                    <textarea id="description2" name="dataDescription" className="form-control" rows="3" value={ele?.dataDescription} onChange={(e) => handleFoundersChange(index, 'dataDescription', e.target.value)}></textarea>
                                                </div>

                                                <div className='d-flex'>
                                                    <div className="form-group mb-6 w-50">
                                                        <label htmlFor={`image-${index}`}>Data Image</label>
                                                        <input
                                                            type="file"
                                                            id={`image-${index}`}
                                                            name="dataImage"
                                                            className="form-control"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageChange(index, e)}
                                                        />
                                                    </div>
                                                    {ele?.dataImage && (
                                                        <div className="mx-5 d-flex align-items-center justify-content-center">
                                                            <img
                                                                className=""
                                                                alt="service"
                                                                width={"100px"}
                                                                height={"100px"}
                                                                src={ele?.dataImage}
                                                                style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 7px 8px rgba(0,0,0,0.5)" }}
                                                            />
                                                            <span
                                                                style={{
                                                                    marginTop: "-4em", fontSize: "20px", cursor: "pointer"
                                                                }}
                                                                onClick={() => handleRemoveImage(index)}
                                                            >
                                                                <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center mt-2'>
                                    <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                </div>
                            </form>
                            <hr />
                        </>
                    }

                    {contentsGetData && !isEdit && (
                        <div className="mt-5">
                            <h3>FrameWork Contents Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>DataHeading</th>
                                        <th>DataDescription</th>
                                        <th>DataImage</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contentsGetData?.length !== 0 ? (
                                        contentsGetData?.map((service, index) => (
                                            <tr key={service._id}>
                                                <td>{service?.dataHeading || '-'}</td>
                                                <td>{service?.dataDescription || '-'}</td>
                                                <td>
                                                    {service?.dataImage ? (
                                                        <img
                                                            alt="Service"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={service?.dataImage}
                                                            style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                        />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>
                                                <td>
                                                    <FaEye
                                                        style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                        onClick={() => handleViewDetails(service)}
                                                        title="View Details"
                                                    />
                                                    <FaEdit
                                                        style={{ cursor: "pointer", color: "#28a745" }}
                                                        onClick={handleEdit}
                                                        title="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No services available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Framework Content Details
                            <span style={{ marginLeft: "455px", cursor: "pointer" }} onClick={toggleModal}>
                                <FaTimes className='' style={{ marginTop: "-80px" }} />
                            </span>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ overflow: "auto", height: "300px" }}>
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>DataHeading</td>
                                            <td>{viewData?.dataHeading || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>DataDescription</td>
                                            <td>{viewData?.dataDescription || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>DataImage</td>
                                            <td>
                                                {viewData?.dataImage ? (
                                                    <img
                                                        alt="Service"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={viewData?.dataImage}
                                                        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default FrameWorkContents