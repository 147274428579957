import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../src/Images/brand.png";
import toast from "react-hot-toast";
import HttpClient from "../utils/HttpClient";
import { sanitizeTitle } from "../utils/sanitizeRouteTitle";

function Sidebar() {
  const [solutionItems, setSolutionItems] = useState([]);
  const [servicesItems, setServicesItems] = useState([]);
  const navigate = useNavigate();
  // const handleClick = () => {
  //   if (document.querySelector("#DarkSwitch").checked) {
  //     document.body.classList.add("drakmode");
  //   } else {
  //     document.body.classList.remove("drakmode");
  //   }
  // };

  const handlClick = () => {
    if (document.querySelector("#backbtnsidebar").click) {
      document.querySelector(".sidebarwrap").classList.remove("show");
    } else {
      document.querySelector(".sidebarwrap").classList.remove("show");
    }
  };

  // >>>>Fetch Solution Data<<<<
  const fetchSolutionData = async () => {
    // setLoading(true);
    const res = await HttpClient.requestData("solution", "GET");
    if (res && res?.status) {
      // setLoading(false);
      // console.log('res?.data: ', res?.data);
      setSolutionItems(res?.data || []);
    } else {
      // setLoading(false);
      setSolutionItems([]);
    }
  }

  // >>>>Fetch Services Data<<<<
  const fetchServicesData = async () => {
    // setLoading(true);
    const res = await HttpClient.requestData("service", "GET");
    if (res && res?.status) {
      // setLoading(false);
      // console.log('res?.data: ', res?.data);
      setServicesItems(res?.data || []);
    } else {
      // setLoading(false);
      setServicesItems([]);
    }
  }

  const handleLogOut = () => {
    localStorage.clear();
    toast.success("Logout SuccessFull");
    navigate("/login");
  };

  useEffect(() => {
    fetchSolutionData();
    fetchServicesData();
  }, []);
  return (
    <>
      <section className="sidebarwrap">
        <div className="top_area">
          <button id="backbtnsidebar" onClick={handlClick}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>

          <div className="logo_area">
            <div className="logo p-2" style={{ backgroundColor: "#eee", borderRadius: "10px" }}>
              <img src={logo} className="img-fluid" alt="logo" />
              {/* <h1 style={{ color: "#fff" }}>Logo</h1> */}
            </div>
          </div>
        </div>

        <div className="sidebar_menu">
          <ul className="nav Menu_Nav accordion" id="sidemenu">

            {/***Menu 1***/}
            <li className="menuline">
              <NavLink to="/" className="" onClick={handlClick}>
                <i className="fa-solid fa-bars"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {/***Menu 2***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead1">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu1"
                  aria-expanded="true"
                  aria-controls="sidemenu1"
                >
                  <i class="fa-solid fa-house"></i>
                  <span>Home</span>
                </Link>
              </div>
              <div
                id="sidemenu1"
                className="collapse"
                aria-labelledby="sidemenuhead1"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Banner</NavLink></li>
                  <li><NavLink to="/our-experience" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Our Experience</NavLink></li>
                  <li><NavLink to="/innovation" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Innovation</NavLink></li>
                  <li><NavLink to="/achievements" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Achievements</NavLink></li>
                  <li><NavLink to="/certification" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Certification</NavLink></li>
                  <li><NavLink to="/services" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Services</NavLink></li>
                  <li><NavLink to="/testimonials" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Testimonials</NavLink></li>
                  <li><NavLink to="/carrer" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Carrer</NavLink></li>
                </ul>
              </div>
            </li>

            {/***Menu 3***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead2">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu2"
                  aria-expanded="true"
                  aria-controls="sidemenu2"
                >
                  <i class="fa-solid fa-address-card"></i>
                  {/* <i className="fa-solid fa-circle-info"></i> */}
                  <span>About</span>
                </Link>
              </div>
              <div
                id="sidemenu2"
                className="collapse"
                aria-labelledby="sidemenuhead2"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/about-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Banner</NavLink></li>
                  <li><NavLink to="/our-story" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Our Story</NavLink></li>
                  <li><NavLink to="/our-mission" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Our Mission</NavLink></li>
                  <li><NavLink to="/our-founders" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Our Founders</NavLink></li>
                  <li><NavLink to="/our-experts" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Our Experts</NavLink></li>
                  <li><NavLink to="/our-advisory" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Our Advisory</NavLink></li>
                </ul>
              </div>
            </li>
            {/** Menu 4 updated **/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead3">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu3"
                  aria-expanded="true"
                  aria-controls="sidemenu3"
                >
                  <i className="fa-solid fa-lightbulb mr-2"></i>
                  <span>Solutions</span>
                </Link>
              </div>

              <div
                id="sidemenu3"
                className="collapse"
                aria-labelledby="sidemenuhead3"
                data-parent="#sidemenu">
                <ul className="Submenu">
                  {
                    solutionItems?.length !== 0 && solutionItems?.map((solutionItem, index) => (
                      <React.Fragment key={index}>
                        {/* Menu Section */}
                        <li className="menu-head" id={`sidemenuhead3${index}`}>
                          <Link
                            className="btn btn-header-link"
                            data-toggle="collapse"
                            data-target={`#sidemenu3${index}`}
                            aria-expanded="true"
                            aria-controls={`sidemenu3${index}`}
                          >
                            {/* Dynamic Icons based on solutionItem title */}
                            {solutionItem?.title === "PHARMA" && <i className="fa-solid fa-capsules mr-2"></i>}
                            {solutionItem?.title === "BFSI" && <i className="fa-solid fa-piggy-bank mr-2"></i>}
                            {solutionItem?.title === "Retail" && <i className="fa-solid fa-store mr-2"></i>}
                            {solutionItem?.title === "Automotive" && <i className="fa-solid fa-car mr-2"></i>}

                            {solutionItem?.title}
                          </Link>
                        </li>
                        {/* Nested submenu */}
                        <div
                          id={`sidemenu3${index}`}
                          className="collapse"
                          aria-labelledby={`sidemenuhead3${index}`}
                          data-parent="#sidemenu3"
                        >
                          <ul className="Submenu">
                            <li>
                              <NavLink to={`/${solutionItem?.title}Banner/${solutionItem?._id}/${solutionItem?.title}`} onClick={handlClick}>
                                <i className="fa-solid fa-angles-right mr-2"></i>Banner
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={`/${solutionItem?.title}Contents/${solutionItem?._id}/${solutionItem?.title}`} onClick={handlClick}>
                                <i className="fa-solid fa-angles-right mr-2"></i>{solutionItem?.title} Contents
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={`/${solutionItem?.title}EndDescription/${solutionItem?._id}/${solutionItem?.title}`} onClick={handlClick}>
                                <i className="fa-solid fa-angles-right mr-2"></i>{solutionItem?.title} End Description
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    ))
                  }
                  {/* <li><NavLink to="/addNewSection" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Add New Solution Section</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/***Menu 4***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead3">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu3"
                  aria-expanded="true"
                  aria-controls="sidemenu3"
                >
                  <i class="fa-solid fa-address-card"></i>
                  <span>Solutions</span>
                </Link>
              </div>
              <div
                id="sidemenu3"
                className="collapse"
                aria-labelledby="sidemenuhead3"
                data-parent="#sidemenu">
                <ul className="Submenu">
                  <li><NavLink to="/solution-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Banner</NavLink></li>
                  <li><NavLink to="/pharma-content" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Solution Contents</NavLink></li>
                  <li><NavLink to="/solution-end-description" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>End Description</NavLink></li>
                </ul>
              </div>
            </li> */}

            {/***Menu 5***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead4">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu4"
                  aria-expanded="true"
                  aria-controls="sidemenu4"
                >
                  <i className="fa-solid fa-user-tie"></i>
                  <span>Career</span>
                </Link>
              </div>
              <div
                id="sidemenu4"
                className="collapse"
                aria-labelledby="sidemenuhead4"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/career-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Banner</NavLink></li>
                  <li><NavLink to="/work-with-us" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Work With Us</NavLink></li>
                  <li><NavLink to="/developers-data" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>All Roles</NavLink></li>
                  <li><NavLink to="/career-side-image" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Side Image</NavLink></li>
                </ul>
              </div>
            </li>

            {/***Menu 6***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead4">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu5"
                  aria-expanded="true"
                  aria-controls="sidemenu5"
                >
                  <i className="fa-solid fa-chalkboard-teacher"></i>
                  <span>Practices</span>
                </Link>
              </div>
              <div
                id="sidemenu5"
                className="collapse"
                aria-labelledby="sidemenuhead5"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/practices-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Pharma Complaince Banner</NavLink></li>
                  <li><NavLink to="/practices-content" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Pharma Complaince Content</NavLink></li>
                  <li><NavLink to="/practices-end-description" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Pharma Complaince End Description</NavLink></li>
                  <li><NavLink to="/cyber-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Cyber Banner</NavLink></li>
                  <li><NavLink to="/cyber-content" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Cyber Content</NavLink></li>
                  <li><NavLink to="/cyber-end-description" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Cyber End Description</NavLink></li>
                </ul>
              </div>
            </li>

            {/***Menu 7***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead7">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu7"
                  aria-expanded="true"
                  aria-controls="sidemenu7"
                >
                  <i className="fa-solid fa-layer-group"></i>
                  <span>FrameWork</span>
                </Link>
              </div>
              <div
                id="sidemenu7"
                className="collapse"
                aria-labelledby="sidemenuhead7"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/framework-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Banner</NavLink></li>
                  <li><NavLink to="/framework-contents" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>FrameWork Contents</NavLink></li>
                </ul>
              </div>
            </li>

            {/***Menu 8***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead8">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu8"
                  aria-expanded="true"
                  aria-controls="sidemenu8"
                >
                  <i class="fa-solid fa-phone"></i>
                  <span>Contact</span>
                </Link>
              </div>
              <div
                id="sidemenu8"
                className="collapse"
                aria-labelledby="sidemenuhead8"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/contact-us" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Contact Us</NavLink></li>
                </ul>
              </div>
            </li>

            {/***Menu 9***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead9">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu9"
                  aria-expanded="true"
                  aria-controls="sidemenu9"
                >
                  <i className="fa-solid fa-info-circle"></i>
                  <span>Footer</span>
                </Link>
              </div>
              <div
                id="sidemenu9"
                className="collapse"
                aria-labelledby="sidemenuhead9"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/footer-info" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Footer Info</NavLink></li>
                </ul>
              </div>
            </li>

            {/** Menu 10 updated **/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead10">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu10"
                  aria-expanded="false"
                  aria-controls="sidemenu10"
                >
                  <i className="fa-solid fa-cogs"></i>
                  <span>Services</span>
                </Link>
              </div>

              <div
                id="sidemenu10"
                className="collapse"
                aria-labelledby="sidemenuhead10"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {servicesItems?.length !== 0 && servicesItems?.map((serviceItem, index) => (
                    <React.Fragment key={index}>
                      {/* Menu Section */}
                      <li className="menu-head" id={`sidemenuhead10_${index}`}>
                        <Link
                          className="btn btn-header-link"
                          data-toggle="collapse"
                          data-target={`#submenu10_${index}`}
                          aria-expanded="false"
                          aria-controls={`submenu10_${index}`}
                        >
                          {/* Dynamic Icons based on serviceItem title */}
                          {serviceItem?.title === "Business Consulting" && <i className="fa-solid fa-handshake mr-2"></i>}
                          {serviceItem?.title === "Staffing Services" && <i className="fa-solid fa-user-tie mr-2"></i>}
                          {serviceItem?.title === "Software Development" && <i className="fa-solid fa-code mr-2"></i>}
                          {serviceItem?.title === "Digital & Analytics" && <i className="fa-solid fa-chart-line mr-2"></i>}
                          {serviceItem?.title === "Cloud Technologies" && <i className="fa-solid fa-cloud mr-2"></i>}
                          {serviceItem?.title === "AI/ML" && <i className="fa-solid fa-robot mr-2"></i>}
                          {serviceItem?.title === "FullStack" && <i className="fa-solid fa-layer-group mr-2"></i>}
                          {serviceItem?.title === "Legacy Modernisation" && <i className="fa-solid fa-sync-alt mr-2"></i>}
                          {serviceItem?.title === "SAP/Oracle" && <i className="fa-solid fa-database mr-2"></i>}
                          {serviceItem?.title === "Mobile Apps" && <i className="fa-solid fa-mobile-alt mr-2"></i>}
                          {serviceItem?.title === "Executive Search" && <i className="fa-solid fa-search-dollar mr-2"></i>}
                          {serviceItem?.title === "Engineering Services" && <i className="fa-solid fa-drafting-compass mr-2"></i>}
                          {serviceItem?.title === "Contigent Staffing" && <i className="fa-solid fa-users mr-2"></i>}
                          {serviceItem?.title === "Msp" && <i className="fa-solid fa-network-wired mr-2"></i>}
                          {serviceItem?.title}
                        </Link>
                      </li>

                      {/* Nested submenu */}
                      <div
                        id={`submenu10_${index}`}
                        className="collapse"
                        aria-labelledby={`sidemenuhead10_${index}`}
                        data-parent="#sidemenu10"
                      >
                        <ul className="Submenu">
                          <li>
                            <NavLink to={`/${sanitizeTitle(serviceItem?.title)?.slice(0, 2).toLowerCase()}ServicesBanner/${serviceItem?._id}/${sanitizeTitle(serviceItem?.title)}`} onClick={handlClick}>
                              <i className="fa-solid fa-angles-right mr-2"></i>Banner
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`/${sanitizeTitle(serviceItem?.title)?.slice(0, 2).toLowerCase()}ServicesContents/${serviceItem?._id}/${sanitizeTitle(serviceItem?.title)}`} onClick={handlClick}>
                              <i className="fa-solid fa-angles-right mr-2"></i>{serviceItem?.title} Services Contents
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to={`/${serviceItem?.title}EndDescription/${serviceItem?._id}/${serviceItem?.title}`} onClick={handlClick}>
                              <i className="fa-solid fa-angles-right mr-2"></i>{serviceItem?.title} End Description
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink to={`/${sanitizeTitle(serviceItem?.title)?.slice(0, 2).toLowerCase()}consultantHeading/${serviceItem?._id}/${sanitizeTitle(serviceItem?.title)}`} onClick={handlClick}>
                              <i className="fa-solid fa-angles-right mr-2"></i>{serviceItem?.title} Consultant Heading
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`/${sanitizeTitle(serviceItem?.title)?.slice(0, 2).toLowerCase()}ConsultantDescription/${serviceItem?._id}/${sanitizeTitle(serviceItem?.title)}`} onClick={handlClick}>
                              <i className="fa-solid fa-angles-right mr-2"></i>{serviceItem?.title} Consultant Description
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </li>




            {/***Menu 10 ***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead8">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu9"
                  aria-expanded="true"
                  aria-controls="sidemenu8"
                >
                  <i className="fa-solid fa-briefcase"></i>
                  <span>Services</span>
                </Link>
              </div>
              <div
                id="sidemenu9"
                className="collapse"
                aria-labelledby="sidemenuhead8"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="/services-banner" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Banner</NavLink></li>
                  <li><NavLink to="/services-content" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Services Content</NavLink></li>
                  <li><NavLink to="/endDescription" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>End Description</NavLink></li>
                </ul>
              </div>
            </li> */}
          </ul>

          <ul className="nav Account_Nav">
            <div onClick={handleLogOut} style={{ width: "100%" }}>
              <Link>
                <i className="fa-solid fa-right-from-bracket mr-2"></i>
                <span>Logout</span>
              </Link>
            </div>
          </ul>
        </div >
      </section >
    </>
  )
}

export default Sidebar