import React, { useEffect, useState } from 'react'
import Loader from '../../Loader/Loader'
import HttpClient from '../../utils/HttpClient';
import { FaEye, FaTimes } from 'react-icons/fa';
import { IoSearchOutline } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    
    // >>>>Data Table Declarations<<<<
    const columns = [
        { name: "SL No.", selector: (row) => row.sln, sortable: true, wrap: true, width: "6rem" },
        { name: "Name", selector: (row) => row.name, sortable: true, wrap: true, width: "10rem" },
        { name: "Email", selector: (row) => row.email, sortable: true, wrap: true, width: "12rem" },
        { name: "Country", selector: (row) => row.country, sortable: true, wrap: true, width: "10rem" },
        { name: "Company", selector: (row) => row.companyName, sortable: true, wrap: true, width: "8rem" },
        { name: "Message", selector: (row) => row.message, sortable: true, wrap: true },
        { name: "Action", selector: (row) => row.action, sortable: true, wrap: true, width: "5.5rem" },
    ];

    //   >>>>Fetch ContactUS Data<<<<
    const fetchContactUsData = async () => {
        setLoading(true)
        const res = await HttpClient.requestData("contacts", "GET");
        if (res && res.status) {
            setLoading(false)
            let arr = res?.data?.map((ele, ind) => {
                return {
                    sln: ind + 1,
                    name: ele?.name,
                    email: ele.email,
                    country: ele?.country,
                    companyName: ele.companyName,
                    message: `${ele.message?.slice(0, 20)}...`,
                    action: (
                        <span
                            title="View"
                            onClick={() => handleView(ele)}
                        >
                            <FaEye
                            style={{cursor:"pointer"}}
                            />
                        </span>
                    )
                }
            })
            setContactData(arr)
        }
    }

    // >>>> Handle search input change<<<<
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // >>>>Handle Search filter<<<<
    const filteredContact = contactData.filter(item =>
        item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.companyName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.message?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.country?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );

    // >>>>Handle Toggling the Modal<<<<
    const toggleModal = () => setModalOpen(!modalOpen);

    // >>>>Handle View Modal<<<<
    const handleView = (data) => {
        toggleModal()
        if (data) {
            setModalData(data)
        } else {
            setModalData("")
        }
    }

    useEffect(() => {
        fetchContactUsData()
    }, [])
    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    <div>
                        <h2 class="">Contact Us</h2>
                    </div>
                    <form class="d-flex bg-white border-secondary rounded mt-3 mb-4">
                        <button
                            class="btn rounded-left shadow-sm"
                            type="button"
                        >
                            <IoSearchOutline className="text-xl" />
                        </button>
                        <input
                            class="form-control w-25 shadow-sm"
                            type="search"
                            placeholder="Search..."
                            aria-label="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </form>
                    {/* DataTable */}
                    <div className='mt-2 overflow-auto '>
                        <DataTable
                            columns={columns}
                            data={filteredContact}
                            pagination={true}
                            responsive={true}
                            highlightOnHover={true}
                        />
                    </div>

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Contact Us Details
                            <span style={{
                                marginLeft: "270px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>{modalData?.name || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{modalData?.email || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Company-Name</td>
                                        <td>{modalData?.companyName || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Message</td>
                                        <td>{modalData?.message || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Country</td>
                                        <td>{modalData?.country || '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div >
        </div >
    )
}

export default Contact