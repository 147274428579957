import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';

const EndDescription = () => {

    const endDesInit = {
        endDescription: "",
    }

    const [endDescriptionData, setEndDescriptionData] = useState(endDesInit);
    const [loading, setLoading] = useState(false);
    const [descriptionGetData, setDescriptionGetData] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [desID, setDesId] = useState("")
    const [ViewData, setViewData] = useState("")
    const toggleModal = () => setModalOpen(!modalOpen);

    // >>>>Handle View Details<<<<
    const handleViewDetails = (data) => {
        toggleModal();
        if (data) {
            setViewData(data)
        } else {
            setViewData("")
        }
    }


    // >>>>Handle Edit<<<<
    const handleEdit = () => {
        setIsEdit(true)
        setEndDescriptionData({
            endDescription: descriptionGetData || '',
        });
    };

    // Handle End Description Input Change
    const handleEndDescriptionChange = (e) => {
        const { name, value } = e.target;
        setEndDescriptionData({ ...endDescriptionData, [name]: value });
    }

    // >>>>Fetch End Description Data<<<<
    const fetchEndDescriptionData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("pharma-complaince", "GET");
        if (res && res?.status) {
            setLoading(false);
            setDesId(res?.data?._id)
            setDescriptionGetData(res.data ? res?.data?.endDescription : "");
        } else {
            setLoading(false);
            setDescriptionGetData("");
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            endDescription: endDescriptionData?.endDescription,
        }

        // return
        setLoading(true)
        const res = await HttpClient.requestData(`pharma-complaince/${desID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setEndDescriptionData(endDesInit)
            setIsEdit(false)
            fetchEndDescriptionData()
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchEndDescriptionData()
    }, [])

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* EndDescription */}
                    {isEdit &&
                        <>
                            <div>
                                <h2>Update End Description</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-12 mb-3">
                                                <label htmlFor="description">End Description</label>
                                                <textarea
                                                    id="description"
                                                    name="endDescription"
                                                    className="form-control"
                                                    rows="3"
                                                    value={endDescriptionData?.endDescription}
                                                    onChange={handleEndDescriptionChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                    </div>
                                </form>
                            </div>

                            <hr />
                        </>
                    }

                    {/* Custom Table to Display Banner Data */}
                    {descriptionGetData && !isEdit && (
                        <div className="mt-5">
                            <h3>End Description Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>End Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{descriptionGetData || '-'}</td>
                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={() => handleViewDetails(descriptionGetData)}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={handleEdit}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>End Description Details
                            <span style={{
                                marginLeft: "440px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-70px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>End Description</td>
                                        <td>{ViewData || '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default EndDescription