
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';

const CyberBanner = () => {
    const bannerInit = {
        title: "",
        whiteHeading: "",
        blueHeading: "",
        mainHeading: "",
        description: "",
        banner: ""
    }
    const [bannerHeadData, setBannerHeadData] = useState(bannerInit);
    const [imageFile, setImageFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [bannerID, setBannerId] = useState("")

    // Handle Banner Head Input Change
    const handleBannerHeadChange = (e) => {
        const { name, value } = e.target;
        setBannerHeadData({ ...bannerHeadData, [name]: value });
    }

    // Handle Image
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true);
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false);
            toast.success("Image Uploaded Successfully");
            setImageFile(res?.url);
            setBannerHeadData({ ...bannerHeadData, banner: res?.url });
        } else {
            setLoading(false);
            setImageFile(null);
            toast.error(res?.message || "Something Went Wrong");
        }
    };

    // Handle Removing Image
    const handleRemoveImage = () => {
        setImageFile(null);
        setBannerHeadData({ ...bannerHeadData, banner: "" });
        document.getElementById("image").value = "";
    };

    // Fetch Banner Data
    const fetchBannerData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("cyber-security", "GET");
        if (res && res?.status) {
            setLoading(false);
            setBannerData(res.data ? res.data : {});
        } else {
            setLoading(false);
            setBannerData({});
        }
    }

    // Handle Edit
    const handleEdit = () => {
        setBannerId(bannerData?._id)
        setIsEdit(true)
        setBannerHeadData({
            title: bannerData.title || '',
            whiteHeading: bannerData.whiteHeading || '',
            blueHeading: bannerData.blueHeading || '',
            mainHeading: bannerData.mainHeading || '',
            description: bannerData.description || '',
            banner: bannerData.banner || ''
        });
        setImageFile(bannerData.banner || null);
    };

    // Toggle Modal
    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = () => {
        toggleModal();
    }

    // Handle Submit Data
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            title: bannerHeadData.title,
            whiteHeading: bannerHeadData.whiteHeading,
            blueHeading: bannerHeadData.blueHeading,
            mainHeading: bannerHeadData.mainHeading,
            description: bannerHeadData.description,
            banner: bannerHeadData.banner
        };
        setLoading(true);
        const res = await HttpClient.requestData(`cyber-security/${bannerID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false);
            toast.success(res?.message || "Data updated successfully!");
            setBannerHeadData(bannerInit);
            fetchBannerData()
            setImageFile(null);
            document.getElementById("image").value = "";
            setIsEdit(false)
        } else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong...");
        }
    }

    useEffect(() => {
        fetchBannerData();
    }, []);

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    
                    {/* CyberBanner */}
                    {isEdit &&
                        <>
                            <div>
                                <h2>Update Cyber Banner</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="heading">Title</label>
                                                <input
                                                    type="text"
                                                    id="heading"
                                                    name="title"
                                                    className="form-control"
                                                    value={bannerHeadData?.title}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>

                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="heading">White-Heading</label>
                                                <input
                                                    type="text"
                                                    id="heading"
                                                    name="whiteHeading"
                                                    className="form-control"
                                                    value={bannerHeadData?.whiteHeading}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>

                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="heading">Blue-Heading</label>
                                                <input
                                                    type="text"
                                                    id="heading"
                                                    name="blueHeading"
                                                    className="form-control"
                                                    value={bannerHeadData?.blueHeading}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>

                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="heading">Main-Heading</label>
                                                <input
                                                    type="text"
                                                    id="heading"
                                                    name="mainHeading"
                                                    className="form-control"
                                                    value={bannerHeadData?.mainHeading}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>

                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="description">Description</label>
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    className="form-control"
                                                    rows="1"
                                                    value={bannerHeadData?.description}
                                                    onChange={handleBannerHeadChange}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className='d-flex'>
                                            <div className="form-group mb-3 w-50">
                                                <label htmlFor="image">Image</label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />
                                            </div>
                                            {imageFile && (
                                                <div className="mx-5 d-flex align-items-center justify-content-center">
                                                    <img
                                                        className="mt-3"
                                                        alt="not found"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={imageFile}
                                                        style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                                                    />
                                                    <span
                                                        style={{ marginTop: "-4rem", fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => handleRemoveImage()}
                                                    >
                                                        <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                    </div>
                                </form>
                            </div>

                            <hr />
                        </>
                    }

                    {/* Custom Table to Display Banner Data */}
                    {bannerData && !isEdit && (
                        <div className="mt-5">
                            <h3>Cyber Banner Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>White-Heading</th>
                                        <th>Blue-Heading</th>
                                        <th>Main-Heading</th>
                                        <th>Description</th>
                                        <th>Banner</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bannerData.title || '-'}</td>
                                        <td>{bannerData.whiteHeading || '-'}</td>
                                        <td>{bannerData.blueHeading || '-'}</td>
                                        <td>{bannerData.mainHeading || '-'}</td>
                                        <td>{bannerData.description || '-'}</td>
                                        <td>
                                            {bannerData.banner ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={bannerData.banner}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={handleViewDetails}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={handleEdit}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Banner Details
                            <span style={{
                                marginLeft: "305px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Title</td>
                                        <td>{bannerData.title || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>White-Heading</td>
                                        <td>{bannerData.whiteHeading || '-'}</td>
                                    </tr><tr>
                                        <td>Blue-Heading</td>
                                        <td>{bannerData.blueHeading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Main-Heading</td>
                                        <td>{bannerData.mainHeading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>{bannerData.description || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Banner</td>
                                        <td>
                                            {bannerData.banner ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={bannerData.banner}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>

                </section>
            </div>
        </div>
    )
}

export default CyberBanner