import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import ReactSelectCreatable from 'react-select/creatable';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';

const Innovation = () => {
    const sectionTwo = {
        heading: "",
        description: "",
        services: [],
        image: ""
    }
    const [sectionTwoData, setSectionTwoData] = useState(sectionTwo);
    const [imageFile, setimageFile] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [section2Data, setSection2Data] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [sectionID, setSectionId] = useState("")


    // Toggle Modal
    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = () => {
        toggleModal();
    }


    // Handle Edit
    const handleEdit = () => {
        setIsEdit(true)
        setSectionTwoData({
            heading: section2Data?.heading || '',
            description: section2Data?.description || '',
            services: section2Data?.services || [],
            image: section2Data?.image || ''
        });
        setimageFile(section2Data?.image || null);
        setSelectedOptions(section2Data?.services?.map(service => ({ value: service, label: service })) || []);
    };

    // >>>>Handle Section Two Input Change<<<<
    const handleSectionTwoChange = (e) => {
        const { name, value } = e.target;
        setSectionTwoData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // >>>>Handle Selected Options for Section 2<<<<
    const handleChange = (options) => {
        setSelectedOptions(options);
        setSectionTwoData((prev) => ({
            ...prev,
            services: options.map(option => option.value)
        }));
    };

    // >>>>Handle Image<<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            setimageFile(res?.url);
            setSectionTwoData({ ...sectionTwoData, image: res?.url })
        } else {
            setLoading(false)
            setimageFile(null)
            toast.error(res?.message || "Something Wrong");
        }
    };

    // >>>Handle Removing Image<<< 
    const handleRemoveImage = () => {
        setimageFile(null);
        setSectionTwoData({ ...sectionTwoData, image: "" })
        document.getElementById("image").value = "";
    };


    // Fetch Section Two Data
    const fetchSectionTwoData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("home", "GET");
        if (res && res?.status) {
            setLoading(false);
            setSectionId(res?.data?._id)
            setSection2Data(res.data ? res?.data?.section2 : {});
        } else {
            setLoading(false);
            setSection2Data({});
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            section2: sectionTwoData,
        }
        console.log("carrerData", data)
        // return
        setLoading(true)
        const res = await HttpClient.requestData(`home/${sectionID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setSectionTwoData(sectionTwo)
            setimageFile(null)
            fetchSectionTwoData()
            setIsEdit(false)
            setSelectedOptions([])
            document.getElementById("image").value = "";
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchSectionTwoData();
    }, []);
    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* Section 2 */}
                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <h2>Update Innovation</h2>
                                    <div className="form-group section" style={{ border: "1px solid #ccc" }}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="heading3">Heading</label>
                                            <input type="text" id="heading3" name="heading" className="form-control" value={sectionTwoData?.heading} onChange={handleSectionTwoChange} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="description3">Description</label>
                                            <textarea id="description3" name="description" className="form-control" rows="3" value={sectionTwoData?.description} onChange={handleSectionTwoChange}></textarea>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="services3">Services</label>
                                            <ReactSelectCreatable
                                                isMulti
                                                value={selectedOptions}
                                                onChange={handleChange}
                                                placeholder="Type and press Enter to add"
                                                noOptionsMessage={() => "Type and press Enter to add"}
                                            />
                                        </div>

                                        <div className='d-flex'>
                                            <div className="form-group mb-3 w-50">
                                                <label htmlFor="image">Image</label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />
                                            </div>
                                            {imageFile && (
                                                <div className="mx-5 d-flex align-item-center justify-content-center">
                                                    <img
                                                        className="mt-3"
                                                        alt="not found"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={imageFile}
                                                        style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                                                    />
                                                    <span className="mt-2"
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => handleRemoveImage()}
                                                    >
                                                        <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button type='submit' className='btn btn-outline-success w-75'>Update</button>
                                </div>
                            </form>
                            <hr />
                        </>
                    }


                    {section2Data && !isEdit && (
                        <div className="mt-5">
                            <h3>Innovation Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Heading</th>
                                        <th>Description</th>
                                        <th>Services</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{section2Data?.heading || '-'}</td>
                                        <td>{section2Data?.description || '-'}</td>
                                        <td>{section2Data?.services?.map(ele => ele)?.join(', ') || '-'}</td>
                                        <td>
                                            {section2Data?.image ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={section2Data?.image}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={handleViewDetails}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={handleEdit}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Innovation Details
                            <span style={{
                                marginLeft: "270px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Heading</td>
                                        <td>{section2Data?.heading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>{section2Data?.description || '-'}</td>
                                    </tr>

                                    <tr>
                                        <td>Services</td>
                                        <td>{section2Data?.services?.map(ele => ele)?.join(', ') || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Image</td>
                                        <td>
                                            {section2Data?.image ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={section2Data?.image}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default Innovation