import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { FiPlusCircle } from 'react-icons/fi';
import { TbCircleMinus } from 'react-icons/tb';
import HttpClient from '../../utils/HttpClient';

const OurMission = () => {
    const missionInit = {
        title: "",
        description: "",
        image: ""
    }
    const [missionData, setMissionData] = useState([missionInit])
    const [loading, setLoading] = useState(false);
    const [imageFile, setimageFile] = useState(null);

    const [missionGetData, setMissionGetData] = useState([]);
    const [bottomImage, setBottomImage] = useState("")
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [missionID, setMissionId] = useState("")
    const [viewData, setViewData] = useState("")


    const toggleModal = () => setModalOpen(!modalOpen);

    // >>>>Handle View Details<<<<
    const handleViewDetails = (data) => {
        toggleModal();
        if (data) {
            setViewData(data)
        } else {
            setViewData("")
        }
    }

    // >>>>Handle Edit<<<<
    const handleEdit = (img) => {
        setIsEdit(true)
        setMissionData(missionGetData?.map(service => ({
            title: service?.title || '',
            description: service?.description || "",
            image: service?.image || ''
        })));
        setimageFile(img)
    };

    // >>>>Handle Mission Input<<<<
    // const handleAddMission = () => {
    //     setMissionData(prev => [...prev, { ...missionInit }]);
    // };

    const handleAddMission = () => {
        setMissionData(prev => [{ ...missionInit }, ...prev]);
    };

    // >>>>Handle Mission Input<<<<
    const handleRemoveMission = (index) => {
        setMissionData(prev => prev.filter((_, i) => i !== index));
    };

    // >>>>Handle Mission Input Change<<<<
    const handleMissionChange = (index, field, value) => {
        setMissionData(prev => {
            const updatedTestimonial = [...prev];
            updatedTestimonial[index] = { ...updatedTestimonial[index], [field]: value };
            return updatedTestimonial;
        });
    };


    // >>>>Handle Image Upload<<<<
    const handleImageChange = async (index, e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            handleMissionChange(index, 'image', res?.url);
        } else {
            setLoading(false)
            toast.error(res?.message || "Something went wrong");
        }
    };

    // >>>>Handle Removing Image<<<<
    const handleRemoveImage = (index) => {
        handleMissionChange(index, 'image', "");
        document.getElementById(`image-${index}`).value = "";
    };

    // >>>>Handle Bottom Image<<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            setimageFile(res?.url);
        } else {
            setLoading(false)
            setimageFile(null)
            toast.error(res?.message || "Something Wrong");
        }
    };

    // >>>Handle Bottom Removing Image<<< 
    const handleRemoveBottomImage = () => {
        setimageFile(null);
        document.getElementById("image").value = "";
    };

    // >>>>Fetch Mission Data<<<<
    const fetchMissionData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("about-cygnus", "GET");
        if (res && res?.status) {
            setLoading(false);
            setBottomImage(res?.data?.bottomImage)
            setMissionId(res?.data?._id)
            setMissionGetData(res.data ? res?.data?.missionVision : []);
        } else {
            setLoading(false);
            setMissionGetData([]);
        }
    }


    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            missionVision: missionData,
            bottomImage: imageFile
        }

        // return
        setLoading(true)
        const res = await HttpClient.requestData(`about-cygnus/${missionID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setMissionData([missionInit])
            setIsEdit(false)
            fetchMissionData()
            document.getElementById("image").value = "";
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchMissionData()
    }, [])

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* OurMission */}

                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <div className='d-flex'>
                                        <h2>Update Mission</h2> <span className='mt-2 mx-2' style={{ cursor: "pointer", color: "green" }} onClick={handleAddMission}><FiPlusCircle /> Add More</span>
                                    </div>
                                    <div style={{ height: "450px", overflow: "auto" }}>
                                        {missionData?.map((ele, index) => (
                                            <div className="form-group section p-2 mt-2" style={{ border: "1px solid #ccc" }}>
                                                <div className="form-group mb-3">
                                                    {index !== -1 && (
                                                        <span
                                                            onClick={() => handleRemoveMission(index)}
                                                        >
                                                            <TbCircleMinus style={{ cursor: "pointer", color: "red", marginTop: "-3px", marginRight: "2px" }} />
                                                        </span>
                                                    )}
                                                    <label htmlFor="heading2">Title</label>
                                                    <input type="text" id="heading2" name="title" className="form-control" value={ele?.title} onChange={(e) => handleMissionChange(index, 'title', e.target.value)} />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="description2">Description</label>
                                                    <textarea id="description2" name="description" className="form-control" rows="3" value={ele?.description} onChange={(e) => handleMissionChange(index, 'description', e.target.value)}></textarea>
                                                </div>

                                                <div className='d-flex'>
                                                    <div className="form-group mb-6 w-50">
                                                        <label htmlFor={`image-${index}`}>Image</label>
                                                        <input
                                                            type="file"
                                                            id={`image-${index}`}
                                                            name="image"
                                                            className="form-control"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageChange(index, e)}
                                                        />
                                                    </div>
                                                    {ele?.image && (
                                                        <div className="mx-5 d-flex align-items-center justify-content-center">
                                                            <img
                                                                className=""
                                                                alt="service"
                                                                width={"100px"}
                                                                height={"100px"}
                                                                src={ele?.image}
                                                                style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 7px 8px rgba(0,0,0,0.5)" }}
                                                            />
                                                            <span
                                                                style={{
                                                                    marginTop: "-4em", fontSize: "20px", cursor: "pointer"
                                                                }}
                                                                onClick={() => handleRemoveImage(index)}
                                                            >
                                                                <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                        }

                                        <div className='d-flex'>
                                            <div className="form-group mb-3 w-50">
                                                <label htmlFor="image">Bottom-Image</label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />
                                            </div>
                                            {imageFile && (
                                                <div className="mx-5 d-flex align-item-center justify-content-center">
                                                    <img
                                                        className="mt-3"
                                                        alt="not found"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={imageFile}
                                                        style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                                                    />
                                                    <span className="mt-2"
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => handleRemoveBottomImage()}
                                                    >
                                                        <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center mt-2'>
                                    <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                </div>
                            </form>
                            <hr />
                        </>
                    }

                    {missionGetData && !isEdit && (
                        <div className="mt-5">
                            <h3>Mission Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Bottom-Image</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        
                                    </tr> */}
                                    {missionGetData?.length !== 0 ? (
                                        missionGetData?.map((service, index) => (
                                            <tr key={service._id}>
                                                {index === 0 ? <td>
                                                    <img src={bottomImage}
                                                        alt=""
                                                        width={"100px"}
                                                        height={"100px"}
                                                        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                    />
                                                </td> :
                                                    <td>{""}</td>
                                                }
                                                <td>{service?.title || '-'}</td>
                                                <td>{service?.description || '-'}</td>
                                                {/* <td>{service?.bottomImage || '-'}</td> */}
                                                <td>
                                                    {service?.image ? (
                                                        <img
                                                            alt="Service"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={service?.image}
                                                            style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                        />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>
                                                <td>
                                                    <FaEye
                                                        style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                        onClick={() => handleViewDetails(service)}
                                                        title="View Details"
                                                    />
                                                    <FaEdit
                                                        style={{ cursor: "pointer", color: "#28a745" }}
                                                        onClick={() => handleEdit(bottomImage)}
                                                        title="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No services available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Mission Details
                            <span style={{ marginLeft: "455px", cursor: "pointer" }} onClick={toggleModal}>
                                <FaTimes className='' style={{ marginTop: "-80px" }} />
                            </span>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ overflow: "auto", height: "300px" }}>
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Bottom-Image</td>
                                            <td><img src={bottomImage} alt="" style={{ width: "100px", height: "100px" }} /></td>
                                        </tr>
                                        <tr>
                                            <td>Title</td>
                                            <td>{viewData?.title || '-'}</td>
                                        </tr>

                                        <tr>
                                            <td>Description</td>
                                            <td>{viewData?.description || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Image</td>
                                            <td>
                                                {viewData?.image ? (
                                                    <img
                                                        alt="Service"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={viewData?.image}
                                                        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default OurMission