import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';

const ConsultantDescription = () => {
    const { id, name } = useParams();
    const bannerInit = {
        conslutionDescription: ''
    };
    const [bannerHeadData, setBannerHeadData] = useState(bannerInit);
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [bannerID, setBannerId] = useState("");

    // Handle Banner Head Input Change
    const handleBannerHeadChange = (e) => {
        const { name, value } = e.target;
        setBannerHeadData({ ...bannerHeadData, [name]: value });
    }


    // Fetch Banner Data
    const fetchBannerData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("service", "GET");
        if (res && res?.status) {
            setLoading(false);
            console.log(res.data?.find(item => item?._id === id).conslutionDescription);

            setBannerData(res?.data?.find(item => item?._id === id) ? res?.data?.find(item => item?._id === id) : {});
        } else {
            setLoading(false);
            setBannerData({});
        }
    }

    // Handle Edit
    const handleEdit = (bannerData) => {
        setBannerId(bannerData?._id);
        setIsEdit(true)
        setBannerHeadData({
            conslutionDescription: bannerData?.conslutionDescription || ''
        });
    };

    // Toggle Modal
    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = () => {
        toggleModal();
    }

    // Handle Submit Data
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            conslutionDescription: bannerHeadData?.conslutionDescription,
        };
        console.log('data: ', data);
        console.log('id: ', bannerData?._id);

        setLoading(true);
        const res = await HttpClient.requestData(`service/${id}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false);
            toast.success(res?.message || "Data updated successfully!");
            setBannerHeadData(bannerInit);
            fetchBannerData()
            setIsEdit(false)
        } else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong...");
        }
    }

    useEffect(() => {
        fetchBannerData();
    }, [id, name]);
    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* Banner */}
                    {isEdit &&
                        <>
                            <div>
                                <h2>Update {name} Consultant Description</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="heading">Consultant Description</label>
                                                <input
                                                    type="text"
                                                    id="heading"
                                                    name="conslutionDescription"
                                                    className="form-control"
                                                    value={bannerHeadData?.conslutionDescription}
                                                    onChange={handleBannerHeadChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                    </div>
                                </form>
                            </div>

                            <hr />
                        </>
                    }

                    {/* Custom Table to Display Banner Data */}
                    {bannerData && !isEdit && (
                        <div className="mt-5">
                            <h3>Consultant {name} Description Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Consultant Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bannerData?.conslutionDescription || '-'}</td>

                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={handleViewDetails}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={() => handleEdit(bannerData)}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Consultant {name} Description
                            <span style={{
                                marginLeft: "305px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Consultant Description</td>
                                        <td>{bannerData.conslutionDescription || '-'}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default ConsultantDescription