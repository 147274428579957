import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';


const SolutionBanner = () => {
  const bannerInit = {
    whiteHeading: "",
    banner: "",
    title: "",
    description: "",
    bannerId: ""
  };
  const [bannerHeadData, setBannerHeadData] = useState(bannerInit);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [bannerID, setBannerId] = useState("")


  // Toggle Modal
  const toggleModal = () => setModalOpen(!modalOpen);

  // Handle View Details
  const handleViewDetails = bannerItem => {
    setBannerHeadData({
      whiteHeading: bannerItem?.whiteHeading || "",
      banner: bannerItem?.banner || "",
      title: bannerItem?.title || "",
      description: bannerItem?.description || "",
      endDescription: bannerItem?.endDescription || "",
      endHeading: bannerItem?.endHeading || "",
    });
    toggleModal();
  }

  // Handle Edit
  const handleDelete = async bannerItem => {
    try {
      setLoading(true);
      const res = await HttpClient.requestData(`solution/${bannerItem?._id}`);
      toast.success(res?.data && "Solution Item has been deleted successfully");
    } catch (error) {
      toast.error(error && "Something Went Wrong can not delete item");
    } finally {
      setLoading(false);
    }
  };



  // Handle Banner Head Input Change
  const handleBannerHeadChange = (e) => {
    const { name, value } = e.target;
    setBannerHeadData({ ...bannerHeadData, [name]: value });
  }

  // Handle Image
  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("Image size must be less than 5 MB");
      return;
    }

    let formData = new FormData();
    formData.append("image", file);
    setLoading(true);
    const res = await HttpClient.fileUplode("image-upload", "POST", formData);
    if (res && res.status) {
      setLoading(false);
      toast.success("Image Uploaded Successfully");
      setImageFile(res?.url);
      setBannerHeadData({ ...bannerHeadData, banner: res?.url });
    } else {
      setLoading(false);
      setImageFile(null);
      toast.error(res?.message || "Something Went Wrong");
    }
  };

  // Handle Removing Image
  const handleRemoveImage = () => {
    setImageFile(null);
    setBannerHeadData({ ...bannerHeadData, banner: "" });
    document.getElementById("image").value = "";
  };

  // >>>>Fetch About Banner Data<<<<
  const fetchBannerData = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("solution", "GET");
    if (res && res?.status) {
      setLoading(false);
      // setBannerId(res?.data?._id)
      setBannerData(res.data ? res?.data?.reverse() : []);
    } else {
      setLoading(false);
      setBannerData([]);
    }
  }

  // >>>>Handle Submit Data<<<<
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      title: bannerHeadData?.title,
      whiteHeading: bannerHeadData?.whiteHeading,
      description: bannerHeadData?.description,
      banner: bannerHeadData?.banner,
      endDescription: bannerHeadData.endDescription,

    };

    setLoading(true);
    const res = await HttpClient.requestData(`solution/${bannerHeadData.bannerId}`, "PUT", data);

    if (res && res?.status) {
      setLoading(false);
      toast.success(res?.message || "Data updated successfully!");

      // Reset form and image field
      setBannerHeadData(bannerInit); // Reset form state
      setImageFile(null); // Clear image preview

      // setIsEdit(false); // Exit edit mode
      fetchBannerData(); // Fetch the updated data

      document.getElementById("image").value = ""; // Clear file input field
    } else {
      setLoading(false);
      toast.error(res?.message || "Something went wrong...!");
    }
  };

  useEffect(() => {
    fetchBannerData()
  }, []);

  return (
    <div className="d-flex justify-content-end">
      {loading && <Loader />}
      <div className="form-header mx-2">
        <section className="piechartsBox_area">
          {/* Custom Table to Display Banner Data */}
          {bannerData && (
            <>
              <div className='m-5'>
                <h3>Add New Solution Section</h3>
                <form onSubmit={handleSubmit}>
                  <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-row">
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          className="form-control"
                          value={bannerHeadData?.title}
                          onChange={handleBannerHeadChange}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="heading">White Heading</label>
                        <input
                          type="text"
                          id="heading"
                          name="whiteHeading"
                          className="form-control"
                          value={bannerHeadData?.whiteHeading}
                          onChange={handleBannerHeadChange}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="description">Description</label>
                        <input
                          type="text"
                          id="description"
                          name="description"
                          className="form-control"
                          value={bannerHeadData?.description}
                          onChange={handleBannerHeadChange}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="form-group mb-3 w-50">
                        <label htmlFor="image">Banner</label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          className="form-control"
                          accept="image/*"
                          onChange={handleImage}
                        />
                      </div>
                      {imageFile && (
                        <div className="mx-5 d-flex align-items-center justify-content-center">
                          <img
                            className="mt-3"
                            alt="not found"
                            width={"100px"}
                            height={"100px"}
                            src={imageFile}
                            style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                          />
                          <span
                            style={{ marginTop: "-4rem", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => handleRemoveImage()}
                          >
                            <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex justify-content-center mt-5'>
                    <button type='submit' className='btn btn-outline-success w-50'>Add</button>
                  </div>
                </form>
              </div>
              <div className="mt-5">
                <h3>All Solution Section Data</h3>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>White Heading</th>
                      <th>Description</th>
                      <th>Banner</th>
                      <th>End Description</th>
                      <th>End Heading</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      bannerData && bannerData?.length !== 0 && bannerData?.map((bannerItem, index) => (
                        <tr key={index}>
                          <td>{bannerItem.title || '-'}</td>
                          <td>{bannerItem.whiteHeading || '-'}</td>
                          <td>{bannerItem.description || '-'}</td>
                          <td>
                            {bannerItem.banner ? (
                              <img
                                alt="Banner"
                                width={"100px"}
                                height={"100px"}
                                src={bannerItem.banner}
                                style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                              />
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{bannerItem.endDescription || '-'}</td>
                          <td>{bannerItem.endHeading || '-'}</td>
                          <td>
                            <FaEye
                              style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                              onClick={() => handleViewDetails(bannerItem)}
                              title="View Details"
                            />
                            <FaEdit
                              style={{ cursor: "pointer", color: "#28a745" }}
                              // onClick={() => handleEdit(bannerItem)}
                              title="Edit"
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </>
          )}

          {/* Modal for Viewing Details */}
          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Banner Details
              <span style={{
                marginLeft: "305px",
                cursor: "pointer"
              }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
            </ModalHeader>
            <ModalBody>
              <table className="table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Title</td>
                    <td>{bannerHeadData?.title || '-'}</td>
                  </tr>
                  <tr>
                    <td>White Heading</td>
                    <td>{bannerHeadData?.whiteHeading || '-'}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{bannerHeadData?.description || '-'}</td>
                  </tr>
                  <tr>
                    <td>Banner</td>
                    <td>
                      {bannerHeadData?.banner ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={bannerHeadData?.banner}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>End Description</td>
                    <td>{bannerHeadData?.endDescription || '-'}</td>
                  </tr>
                  <tr>
                    <td>End Heading</td>
                    <td>{bannerHeadData?.endHeading || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </Modal>
        </section>
      </div>
    </div>
  )
}

export default SolutionBanner
