import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';

const FooterInfo = () => {
  const footerInit = {
    heading: "",
    phoneNo: "",
    email: "",
    address1: "",
    address1Logo: "",
    address2: "",
    address2Logo: "",
    image: ""
  };
  const [footerHeadData, setFooterHeadData] = useState(footerInit);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [footerData, setFooterData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [footerID, setFooterId] = useState("");


  // Toggle Modal
  const toggleModal = () => setModalOpen(!modalOpen);

  // Handle View Details
  const handleViewDetails = () => {
    toggleModal();
  }

  // Handle Edit
  const handleEdit = () => {
    setIsEdit(true);
    setFooterHeadData({
      heading: footerData?.heading || '',
      phoneNo: footerData?.phoneNo || '',
      email: footerData?.email || '',
      address1: footerData?.address1 || '',
      address1Logo: footerData?.address1Logo || '', // This should already be set
      address2: footerData?.address2 || '',
      address2Logo: footerData?.address2Logo || '', // This should already be set
      image: footerData?.image || ''
    });

    // Set imageFile to the respective logos and image
    setImageFile({
      address1Logo: footerData?.address1Logo || null,
      address2Logo: footerData?.address2Logo || null,
      image: footerData?.image || null
    });
  };




  // Handle Banner Head Input Change
  const handleFooterHeadChange = (e) => {
    const { name, value } = e.target;
    setFooterHeadData({ ...footerHeadData, [name]: value });
  }

  // Handle Image
  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("Image size must be less than 5 MB");
      return;
    }

    let formData = new FormData();
    formData.append(e.target.name, file);
    setLoading(true);
    const res = await HttpClient.fileUplode("image-upload", "POST", formData);
    if (res && res.status) {
      setLoading(false);
      toast.success("Image Uploaded Successfully");
      setImageFile(res?.url);
      setFooterHeadData({ ...footerHeadData, [e.target.name]: res?.url });
    } else {
      setLoading(false);
      setImageFile(null);
      toast.error(res?.message || "Something Went Wrong");
    }
  };

  // Handle Removing Image
  const handleRemoveImage = (type) => {
    if (type === 'address1Logo') {
      setImageFile({ ...imageFile, address1Logo: null });
      setFooterHeadData({ ...footerHeadData, address1Logo: "" });
    } else if (type === 'address2Logo') {
      setImageFile({ ...imageFile, address2Logo: null });
      setFooterHeadData({ ...footerHeadData, address2Logo: "" });
    } else if (type === 'image') {
      setImageFile({ ...imageFile, image: null });
      setFooterHeadData({ ...footerHeadData, image: "" });
    }
    document.getElementById(type).value = "";
  };

  // >>>>Fetch About Banner Data<<<<
  const fetchBannerData = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("footer", "GET");
    if (res && res?.status) {
      setLoading(false);
      setFooterId(res?.data?._id)
      setFooterData(res.data ? res?.data : {});
    } else {
      setLoading(false);
      setFooterData({});
    }
  }

  // >>>>Handle Submit Data<<<<
  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = {
      heading: footerHeadData?.heading,
      phoneNo: footerHeadData?.phoneNo,
      email: footerHeadData?.email,
      address1: footerHeadData?.address1,
      address1Logo: footerHeadData?.address1Logo,
      address2: footerHeadData?.address2,
      address2Logo: footerHeadData?.address2Logo,
      image: footerHeadData?.image
    }
    console.log("footerData", data)

    // return
    setLoading(true)
    const res = await HttpClient.requestData(`footer/${footerID}`, "PUT", data);
    if (res && res?.status) {
      setLoading(false)
      toast.success(res?.message || "Data updated successfully.!");
      setFooterData(footerInit)
      setIsEdit(false)
      fetchBannerData()
      document.getElementById("image").value = "";
    } else {
      setLoading(false)
      return toast.error(res?.message || "Something went wrong...!")
    }
  }

  useEffect(() => {
    fetchBannerData()
  }, []);
  return (
    <div className="d-flex justify-content-end">
      {loading && <Loader />}
      <div className="form-header mx-2">
        <section className="piechartsBox_area">
          {isEdit &&
            <>
              <div>
                <h2>Update Footer Information</h2>
                <form onSubmit={handleSubmit}>
                  <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-row">
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="heading">Heading</label>
                        <input
                          type="text"
                          id="heading"
                          name="heading"
                          className="form-control"
                          value={footerHeadData?.heading}
                          onChange={handleFooterHeadChange}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="phoneNo">Phone No</label>
                        <input
                          type="tel"
                          id="phoneNo"
                          name="phoneNo"
                          className="form-control"
                          value={footerHeadData?.phoneNo}
                          onChange={handleFooterHeadChange}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="email">Email ID</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={footerHeadData?.email}
                          onChange={handleFooterHeadChange}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="address1">Address 1</label>
                        <textarea
                          id="address1"
                          name="address1"
                          className="form-control"
                          rows="1"
                          value={footerHeadData?.address1}
                          onChange={handleFooterHeadChange}
                        ></textarea>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div className="form-group mb-3 w-50">
                        <label htmlFor="address1Logo">Address 1 Logo</label>
                        <input
                          type="file"
                          id="address1Logo"
                          name="address1Logo"
                          className="form-control"
                          accept="image/*"
                          onChange={handleImage}
                        />
                      </div>
                      {imageFile.address1Logo && (
                        <div className="mx-5 d-flex align-items-center justify-content-center">
                          <img
                            className="mt-3"
                            alt="Address 1 Logo"
                            width={"100px"}
                            height={"100px"}
                            src={imageFile.address1Logo}
                            style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                          />
                          <span
                            style={{ marginTop: "-4rem", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => handleRemoveImage('address1Logo')}
                          >
                            <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label htmlFor="address2">Address 2</label>
                      <textarea
                        id="address2"
                        name="address2"
                        className="form-control"
                        rows="3"
                        value={footerHeadData?.address2}
                        onChange={handleFooterHeadChange}
                      ></textarea>
                    </div>
                    <div className='d-flex'>
                      <div className="form-group mb-3 w-50">
                        <label htmlFor="address2Logo">Address 2 Logo</label>
                        <input
                          type="file"
                          id="address2Logo"
                          name="address2Logo"
                          className="form-control"
                          accept="image/*"
                          onChange={handleImage}
                        />
                      </div>
                      {imageFile.address2Logo && (
                        <div className="mx-5 d-flex align-items-center justify-content-center">
                          <img
                            className="mt-3"
                            alt="Address 2 Logo"
                            width={"100px"}
                            height={"100px"}
                            src={imageFile.address2Logo}
                            style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                          />
                          <span
                            style={{ marginTop: "-4rem", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => handleRemoveImage('address2Logo')}
                          >
                            <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className='d-flex'>
                      <div className="form-group mb-3 w-50">
                        <label htmlFor="image">Image</label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          className="form-control"
                          accept="image/*"
                          onChange={handleImage}
                        />
                      </div>
                      {imageFile.image && (
                        <div className="mx-5 d-flex align-items-center justify-content-center">
                          <img
                            className="mt-3"
                            alt="Address 2 Logo"
                            width={"100px"}
                            height={"100px"}
                            src={imageFile.image}
                            style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                          />
                          <span
                            style={{ marginTop: "-4rem", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => handleRemoveImage('address2Logo')}
                          >
                            <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex justify-content-center mt-5'>
                    <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                  </div>
                </form>
              </div>

              <hr />
            </>
          }
          {/* Custom Table to Display Banner Data */}
          {footerData && !isEdit && (
            <div className="mt-5">
              <h3>Footer Information</h3>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Heading</th>
                    <th>Phone No</th>
                    <th>Email Id</th>
                    <th>Address 1</th>
                    <th>Address 1 Logo</th>
                    <th>Address 2</th>
                    <th>Address 2 Logo</th>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{footerData.heading || '-'}</td>
                    <td>{footerData.phoneNo || '-'}</td>
                    <td>{footerData.email || '-'}</td>
                    <td>{footerData.address1 || '-'}</td>
                    <td>
                      {footerData.address1Logo ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={footerData.address1Logo}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{footerData.address2 || '-'}</td>
                    <td>
                      {footerData.address2Logo ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={footerData.address2Logo}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {footerData.image ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={footerData.image}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <FaEye
                        style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                        onClick={handleViewDetails}
                        title="View Details"
                      />
                      <FaEdit
                        style={{ cursor: "pointer", color: "#28a745" }}
                        onClick={handleEdit}
                        title="Edit"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {/* Modal for Viewing Details */}
          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Footer Details
              <span style={{
                marginLeft: "305px",
                cursor: "pointer"
              }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
            </ModalHeader>
            <ModalBody>
              <table className="table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Heading</td>
                    <td>{footerData?.heading || '-'}</td>
                  </tr>
                  <tr>
                    <td>Phone No</td>
                    <td>{footerData?.phoneNo || '-'}</td>
                  </tr>
                  <tr>
                    <td>Email Id</td>
                    <td>{footerData?.email || '-'}</td>
                  </tr>
                  <tr>
                    <td>Address 1</td>
                    <td>{footerData?.address1 || '-'}</td>
                  </tr>
                  <tr>
                    <td>Address 1 Logo</td>
                    <td>
                      {footerData?.address1Logo ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={footerData?.address1Logo}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Address 2</td>
                    <td>{footerData?.address2 || '-'}</td>
                  </tr>
                  <tr>
                    <td>Address 2 Logo</td>
                    <td>
                      {footerData?.address2Logo ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={footerData?.address2Logo}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Image</td>
                    <td>
                      {footerData?.image ? (
                        <img
                          alt="Banner"
                          width={"100px"}
                          height={"100px"}
                          src={footerData?.image}
                          style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </Modal>
        </section>
      </div>
    </div>
  )
}

export default FooterInfo