import React, { useState, useEffect } from 'react'
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import { Table } from 'reactstrap';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import toast from 'react-hot-toast';

const SideImage = () => {
    const [imageFile, setImageFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sideImage, setSideImage] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [bannerID, setBannerId] = useState("")


    // >>>>Handle Edit<<<<
    const handleEdit = () => {
        setIsEdit(true)
        setImageFile(sideImage || null);
    };

    // >>>>Handle Image<<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true);
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false);
            toast.success("Image Uploaded Successfully");
            setImageFile(res?.url);
        } else {
            setLoading(false);
            setImageFile(null);
            toast.error(res?.message || "Something Went Wrong");
        }
    };

    // >>>>Handle Removing Image<<<<
    const handleRemoveImage = () => {
        setImageFile(null);
        document.getElementById("image").value = "";
    };

    // >>>>Fetch Career Side Image Data<<<<
    const fetchCareerSideImageData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("career", "GET");
        if (res && res?.status) {
            setLoading(false);
            setBannerId(res?.data?._id)
            setSideImage(res?.data ? res?.data?.image : "");
        } else {
            setLoading(false);
            setBannerId("")
            setSideImage("");
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            image: imageFile
        };
        setLoading(true);
        const res = await HttpClient.requestData(`career/${bannerID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false);
            toast.success(res?.message || "Data updated successfully!");
            fetchCareerSideImageData()
            setImageFile(null);
            document.getElementById("image").value = "";
            setIsEdit(false)
        } else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong...");
        }
    }

    useEffect(() => {
        fetchCareerSideImageData()
    }, [])

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* SideImage */}

                    {isEdit &&
                        <>
                            <div>
                                <h2>Update Career Side Image</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='p-2' style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                                        <div className='d-flex'>
                                            <div className="form-group mb-3 w-50">
                                                <label htmlFor="image">Career-Baner</label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />
                                            </div>
                                            {imageFile && (
                                                <div className="mx-5 d-flex align-items-center justify-content-center">
                                                    <img
                                                        className="mt-3"
                                                        alt="not found"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={imageFile}
                                                        style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                                                    />
                                                    <span
                                                        style={{ marginTop: "-4rem", fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => handleRemoveImage()}
                                                    >
                                                        <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                    </div>
                                </form>
                            </div>

                            <hr />
                        </>
                    }

                    {/* Custom Table to Display Banner Data */}
                    {sideImage && !isEdit && (
                        <div className="mt-5">
                            <h3>Career Side Image Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Career Banner</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {sideImage ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={sideImage}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td>
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={handleEdit}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                </section>
            </div>
        </div>
    )
}

export default SideImage