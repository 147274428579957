import React, { useEffect, useState } from 'react'
import HttpClient from '../../utils/HttpClient'
import toast from 'react-hot-toast'
import { FiPlusCircle } from 'react-icons/fi';
import { TbCircleMinus } from 'react-icons/tb';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';

const Services = () => {
    const serviceInit = {
        service: "",
        image: ""
    };
    const [serviceData, setServiceData] = useState([serviceInit]);
    const [loading, setLoading] = useState(false);

    const [serviceGetData, setServiceGetData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [sectionID, setSectionId] = useState("")
    const [viewData, setViewData] = useState("")

    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = (data) => {
        toggleModal();
        if (data) {
            setViewData(data)
        } else {
            setViewData("")
        }
    }



    // Handle Edit
    const handleEdit = () => {
        setIsEdit(true)
        setServiceData(serviceGetData?.map(service => ({
            service: service?.service || '',
            image: service?.image || ''
        })));
    };


    // >>>>Handle Incrementing Service Input<<<<
    // const handleAddService = () => {
    //     setServiceData(prev => [...prev, { ...serviceInit }]);
    // };

    const handleAddService = () => {
        setServiceData(prev => [{ ...serviceInit }, ...prev]);
    };

    // >>>>Handle Decrementing Service Input<<<<
    const handleRemoveService = (index) => {
        setServiceData(prev => prev.filter((_, i) => i !== index));
    };

    // >>>>Handle Service Input Change<<<<
    const handleServiceChange = (index, field, value) => {
        setServiceData(prev => {
            const updatedServices = [...prev];
            updatedServices[index] = { ...updatedServices[index], [field]: value };
            return updatedServices;
        });
    };

    // >>>>Handle Image Upload<<<<
    const handleImageChange = async (index, e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            handleServiceChange(index, 'image', res?.url);
        } else {
            setLoading(false)
            toast.error(res?.message || "Something went wrong");
        }
    };

    // >>>>Handle Removing Image<<<<
    const handleRemoveImage = (index) => {
        handleServiceChange(index, 'image', "");
        document.getElementById(`image-${index}`).value = "";
    };

    // Fetch Servies Data
    const fetchServiesData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("home", "GET");
        if (res && res?.status) {
            setLoading(false);
            setSectionId(res?.data?._id)
            setServiceGetData(res.data ? res?.data?.Services : []);
        } else {
            setLoading(false);
            setServiceGetData([]);
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            Services: serviceData,
        }
        console.log("carrerData", data)

        // return
        setLoading(true)
        const res = await HttpClient.requestData(`home/${sectionID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!")
            setServiceData([serviceInit]);
            setIsEdit(false)
            fetchServiesData()
            document.querySelectorAll('input[type="file"]').forEach(input => input.value = '');
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchServiesData()
    }, [])
    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* Services */}
                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <div className='d-flex'>
                                        <h2 >Services</h2> <span className='mt-2 mx-1' style={{ cursor: "pointer", color: "green" }} onClick={handleAddService}><FiPlusCircle /> Add More Services</span>
                                    </div>
                                    <div className='p-2' style={{ height: "350px", overflow: "auto" }}>
                                        {serviceData?.map((ele, index) => (
                                            <div className="form-row p-2 mt-2" style={{ border: "1px solid #ccc" }}>
                                                <div className="form-group col-md-6 mb-3">
                                                    {index !== -1 && (
                                                        <span
                                                            onClick={() => handleRemoveService(index)}
                                                        >
                                                            <TbCircleMinus style={{ cursor: "pointer", color: "red", marginTop: "-3px", marginRight: "2px" }} />
                                                        </span>
                                                    )}
                                                    <label htmlFor="heading">Service</label>
                                                    <input
                                                        type="text"
                                                        id="heading"
                                                        name="service"
                                                        className="form-control"
                                                        value={ele?.service}
                                                        onChange={(e) => handleServiceChange(index, 'service', e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-6 w-50">
                                                    <label htmlFor={`image-${index}`}>Image</label>
                                                    <input
                                                        type="file"
                                                        id={`image-${index}`}
                                                        name="image"
                                                        className="form-control"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageChange(index, e)}
                                                    />
                                                </div>
                                                {ele.image && (
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img
                                                            className=""
                                                            alt="service"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={ele.image}
                                                            style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 8px rgba(0,0,0,0.5)" }}
                                                        />
                                                        <span
                                                            style={{ marginTop: "-4em", fontSize: "20px", cursor: "pointer" }}
                                                            onClick={() => handleRemoveImage(index)}
                                                        >
                                                            <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                        }
                                    </div>

                                    <div className='d-flex justify-content-center mt-2'>
                                        <button type='submit' className='btn btn-outline-success w-75'>Update</button>
                                    </div>
                                </div>
                            </form>
                            <hr />
                        </>
                    }

                    {serviceGetData && !isEdit && (
                        <div className="mt-5">
                            <h3>Career Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Service</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceGetData?.length !== 0 ? (
                                        serviceGetData.map((service, index) => (
                                            <tr key={service._id}>
                                                <td>{service.service || '-'}</td>
                                                <td>
                                                    {service.image ? (
                                                        <img
                                                            alt="Service"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={service.image}
                                                            style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                        />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>
                                                <td>
                                                    <FaEye
                                                        style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                        onClick={() => handleViewDetails(service)}
                                                        title="View Details"
                                                    />
                                                    <FaEdit
                                                        style={{ cursor: "pointer", color: "#28a745" }}
                                                        onClick={handleEdit}
                                                        title="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No services available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Service Details
                            <span style={{ marginLeft: "455px", cursor: "pointer" }} onClick={toggleModal}>
                                <FaTimes className='' style={{ marginTop: "-80px" }} />
                            </span>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ overflow: "auto", height: "300px" }}>
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Service</td>
                                            <td>{viewData?.service || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Image</td>
                                            <td>
                                                {viewData?.image ? (
                                                    <img
                                                        alt="Service"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={viewData?.image}
                                                        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default Services