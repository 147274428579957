import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
import HttpClient from '../../utils/HttpClient';
const OurStory = () => {
    const ourstoryInit = {
        title: "",
        heading: "",
        description: "",
        banner: ""
    }
    const [ourStoryData, setOurStoryData] = useState(ourstoryInit)
    const [loading, setLoading] = useState(false);
    const [imageFile, setimageFile] = useState(null);

    const [ourStoryGetData, setOurStoryGetData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [ourStoryID, setOurStoryId] = useState("")

    // >>>>Toggle the modal<<<<
    const toggleModal = () => setModalOpen(!modalOpen);

    // >>>>Handle View Details<<<<
    const handleViewDetails = () => {
        toggleModal();
    }

    // >>>>Handle Edit<<<<
    const handleEdit = () => {
        setIsEdit(true)
        setOurStoryData({
            title: ourStoryGetData?.title || '',
            heading: ourStoryGetData?.heading || '',
            description: ourStoryGetData?.description || '',
            banner: ourStoryGetData?.banner || ''
        });
        setimageFile(ourStoryGetData?.banner || null);
    };

    // >>>>Handle Our Story Input Change<<<<
    const handleOurStoryChange = (e) => {
        const { name, value } = e.target;
        setOurStoryData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // >>>>Handle Image<<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            setimageFile(res?.url);
            setOurStoryData({ ...ourStoryData, banner: res?.url })
        } else {
            setLoading(false)
            setimageFile(null)
            toast.error(res?.message || "Something Wrong");
        }
    };

    // >>>Handle Removing Image<<< 
    const handleRemoveImage = () => {
        setimageFile(null);
        setOurStoryData({ ...ourStoryData, banner: "" })
        document.getElementById("image").value = "";
    };

    // >>>>Fetch Our Story Data<<<<
    const fetchOurStoryData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("about-cygnus", "GET");
        if (res && res?.status) {
            setLoading(false);
            setOurStoryId(res?.data?._id)
            setOurStoryGetData(res?.data ? res?.data : {});
        } else {
            setLoading(false);
            setOurStoryGetData({});
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const { title, heading, description, banner } = ourStoryData
        const data = {
            title,
            heading,
            description,
            banner
        }
        // return
        setLoading(true)
        const res = await HttpClient.requestData(`about-cygnus/${ourStoryID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setOurStoryGetData(ourstoryInit)
            setimageFile(null)
            setIsEdit(false)
            document.getElementById("image").value = "";
            fetchOurStoryData()
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchOurStoryData()
    }, [])

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* OurStory */}
                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <h2>Update Our Story</h2>
                                    <div className="form-group section" style={{ border: "1px solid #ccc" }}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="heading2">Title</label>
                                            <input type="text" id="heading2" name="title" className="form-control" value={ourStoryData?.title} onChange={handleOurStoryChange} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="heading2">Heading</label>
                                            <input type="text" id="heading2" name="heading" className="form-control" value={ourStoryData?.heading} onChange={handleOurStoryChange} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="description2">Description</label>
                                            <textarea id="description2" name="description" className="form-control" rows="3" value={ourStoryData?.description} onChange={handleOurStoryChange}></textarea>
                                        </div>

                                        <div className='d-flex'>
                                            <div className="form-group mb-3 w-50">
                                                <label htmlFor="image">Banner</label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />
                                            </div>
                                            {imageFile && (
                                                <div className="mx-5 d-flex align-item-center justify-content-center">
                                                    <img
                                                        className="mt-3"
                                                        alt="not found"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={imageFile}
                                                        style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                                                    />
                                                    <span className="mt-2"
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => handleRemoveImage()}
                                                    >
                                                        <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                </div>
                            </form>
                            <hr />
                        </>
                    }

                    {ourStoryGetData && !isEdit && (
                        <div className="mt-5">
                            <h3>Our Story Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Heading</th>
                                        <th>Description</th>
                                        <th>Banner</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{ourStoryGetData?.title || '-'}</td>
                                        <td>{ourStoryGetData?.heading || '-'}</td>
                                        <td>{ourStoryGetData?.description || '-'}</td>
                                        <td>
                                            {ourStoryGetData?.banner ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={ourStoryGetData?.banner}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={handleViewDetails}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={handleEdit}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Our Experience Details
                            <span style={{
                                marginLeft: "225px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Title</td>
                                        <td>{ourStoryGetData?.title || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Heading</td>
                                        <td>{ourStoryGetData?.heading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>{ourStoryGetData?.description || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Banner</td>
                                        <td>
                                            {ourStoryGetData?.banner ? (
                                                <img
                                                    alt="Banner"
                                                    width={"100px"}
                                                    height={"100px"}
                                                    src={ourStoryGetData?.banner}
                                                    style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>

                </section>
            </div>
        </div>
    )
}

export default OurStory