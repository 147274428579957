import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
const Certification = () => {
    const sectionFour = {
        heading: "",
        description: "",
        image: []
    }
    const [sectionFourData, setSectionFourData] = useState(sectionFour)
    const [loading, setLoading] = useState(false);
    const [imageInputIndex, setImageInputIndex] = useState(0);

    const [section4Data, setSection4Data] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [sectionID, setSectionId] = useState("")


    const toggleModal = () => setModalOpen(!modalOpen);

    // Handle View Details
    const handleViewDetails = () => {
        toggleModal();
    }


    // Handle Edit
    const handleEdit = () => {
        setIsEdit(true)
        setSectionFourData({
            heading: section4Data?.heading || '',
            description: section4Data?.description || '',
            image: section4Data?.image || ''
        });
    };


    // >>>>Handle Section Four Input Change<<<<
    const handleSectionFourChange = (e) => {
        const { name, value } = e.target;
        setSectionFourData({ ...sectionFourData, [name]: value })
    }

    // >>>>Handle Image<<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            setSectionFourData(prevState => ({
                ...prevState,
                image: [...prevState.image, res?.url]
            }));
            setImageInputIndex(prevIndex => prevIndex + 1);
        } else {
            setLoading(false)
            toast.error(res?.message || "Something went wrong");
        }
    };


    // >>>>Remove Image by Index<<<<
    const handleRemoveImage = (index) => {
        setImageInputIndex(prevIndex => prevIndex - 1);
        setSectionFourData(prevState => ({
            ...prevState,
            image: prevState.image.filter((_, i) => i !== index)
        }));
        document.getElementById(`image${imageInputIndex}`).value = "";
    };

    // Fetch Section Four Data
    const fetchSectionFourData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("home", "GET");
        if (res && res?.status) {
            setLoading(false);
            setSectionId(res?.data?._id)
            setSection4Data(res.data ? res?.data?.section4 : {});
        } else {
            setLoading(false);
            setSection4Data({});
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            section4: sectionFourData,
        }
        console.log("carrerData", data)
        // return
        setLoading(true)
        const res = await HttpClient.requestData(`home/${sectionID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setSectionFourData(sectionFour)
            setIsEdit(false)
            fetchSectionFourData()
            setImageInputIndex(0)
            document.getElementById(`image${imageInputIndex}`).value = "";
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchSectionFourData()
    }, [])
    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* Section 4 */}
                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <h2 >Update Certification</h2>
                                    <div className="form-group section" style={{ border: "1px solid #ccc" }}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="heading5">Heading</label>
                                            <input type="text" id="heading5" name="heading" className="form-control" value={sectionFourData?.heading} onChange={handleSectionFourChange} />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label htmlFor="description5">Description</label>
                                            <textarea id="description5" name="description" className="form-control" rows="3" value={sectionFourData?.description} onChange={handleSectionFourChange}></textarea>
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <div className="form-group mb-3">
                                                <label htmlFor={`image-${imageInputIndex}`}>Image {imageInputIndex > 0 && imageInputIndex}</label>
                                                <input
                                                    type="file"
                                                    id={`image${imageInputIndex}`}
                                                    name="image"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />
                                            </div>

                                            {sectionFourData?.image?.length > 0 && (
                                                <div className="d-flex flex-wrap">
                                                    {sectionFourData?.image?.map((img, index) => (
                                                        <div key={index} className="position-relative mx-3 mb-2">
                                                            <img
                                                                alt={`uploaded-${index}`}
                                                                width="100px"
                                                                height="100px"
                                                                src={img}
                                                                style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 10px 10px rgba(0,0,0,0.5)" }}
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger position-absolute top-0 end-0"
                                                                style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                                                                onClick={() => handleRemoveImage(index)}
                                                            >
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <button type='submit' className='btn btn-outline-success w-75'>Update</button>
                                </div>
                            </form>
                            <hr />
                        </>
                    }

                    {section4Data && !isEdit && (
                        <div className="mt-5">
                            <h3>Certification Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Heading</th>
                                        <th>Description</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{section4Data?.heading || '-'}</td>
                                        <td>{section4Data?.description || '-'}</td>
                                        <td>
                                            {section4Data?.image?.length !== 0 ? (
                                                section4Data?.image?.map((ele, ind) => {
                                                    return (
                                                        <img
                                                            className='mx-1'
                                                            key={ind}
                                                            alt="Banner"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={ele}
                                                            style={{ border: "1px solid #ddd", borderRadius: "8px", boxShadow: "0 10px 8px rgba(0,0,0,0.5)" }}
                                                        />
                                                    )
                                                })
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td>
                                            <FaEye
                                                style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                onClick={handleViewDetails}
                                                title="View Details"
                                            />
                                            <FaEdit
                                                style={{ cursor: "pointer", color: "#28a745" }}
                                                onClick={handleEdit}
                                                title="Edit"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Certification Details
                            <span style={{
                                marginLeft: "253px",
                                cursor: "pointer"
                            }} onClick={() => { toggleModal() }}><FaTimes className='' style={{ marginTop: "-20px" }} /></span>
                        </ModalHeader>
                        <ModalBody>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Heading</td>
                                        <td>{section4Data?.heading || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>{section4Data?.description || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Image</td>
                                        <td>
                                            {section4Data?.image?.length !== 0 ? (
                                                section4Data?.image?.map((ele, ind) => {
                                                    return (
                                                        <img
                                                            className='mx-1'
                                                            key={ind}
                                                            alt="Banner"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={ele}
                                                            style={{ border: "1px solid #ddd", borderRadius: "8px", boxShadow: "0 10px 8px rgba(0,0,0,0.5)" }}
                                                        />
                                                    )
                                                })
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default Certification