import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import { TbCircleMinus } from 'react-icons/tb';
import { FiPlusCircle } from 'react-icons/fi';
import Loader from '../../Loader/Loader';
import { Modal, ModalHeader, ModalBody, Button, Table } from 'reactstrap';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';

const WorkWithUs = () => {
    const workInit = {
        heading: "",
        description: "",
        image: ""
    }
    const [workWithUsData, setWorkWithUsData] = useState([workInit])
    const [loading, setLoading] = useState(false);

    const [workWithUsGetData, setWorkWithUsGetData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [expertID, setExpertId] = useState("")
    const [viewData, setViewData] = useState("")


    const toggleModal = () => setModalOpen(!modalOpen);

    // >>>>Handle View Details<<<<
    const handleViewDetails = (data) => {
        toggleModal();
        if (data) {
            setViewData(data)
        } else {
            setViewData("")
        }
    }


    // >>>>Handle Edit<<<<
    const handleEdit = () => {
        setIsEdit(true)
        setWorkWithUsData(workWithUsGetData?.map(service => ({
            heading: service?.heading || '',
            description: service?.description || "",
            image: service?.image || ''
        })));
    };

    // >>>>Handle Incrementing WWU Input<<<<
    // const handleAddWWU = () => {
    //     setWorkWithUsData(prev => [...prev, { ...workInit }]);
    // };
    const handleAddWWU = () => {
        setWorkWithUsData(prev => [{ ...workInit }, ...prev]);
    };

    // >>>>Handle Decrementing WWU Input<<<<
    const handleRemoveWWU = (index) => {
        setWorkWithUsData(prev => prev.filter((_, i) => i !== index));
    };

    // >>>>Handle WWU Input Change<<<<
    const handleWWUChange = (index, field, value) => {
        setWorkWithUsData(prev => {
            const updatedTestimonial = [...prev];
            updatedTestimonial[index] = { ...updatedTestimonial[index], [field]: value };
            return updatedTestimonial;
        });
    };

    // >>>>Handle Image Upload<<<<
    const handleImageChange = async (index, e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("image", file);
        setLoading(true)
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        if (res && res.status) {
            setLoading(false)
            toast.success("Image Uploaded Successfully");
            handleWWUChange(index, 'image', res?.url);
        } else {
            setLoading(false)
            toast.error(res?.message || "Something went wrong");
        }
    };

    // >>>>Handle Removing Image<<<<
    const handleRemoveImage = (index) => {
        handleWWUChange(index, 'image', "");
        document.getElementById(`image-${index}`).value = "";
    };


    // >>>>Fetch WWU Data<<<<
    const fetchWWUData = async () => {
        setLoading(true);
        const res = await HttpClient.requestData("career", "GET");
        if (res && res?.status) {
            setLoading(false);
            setExpertId(res?.data?._id)
            setWorkWithUsGetData(res?.data ? res?.data?.workWithUs : []);
        } else {
            setLoading(false);
            setWorkWithUsGetData([]);
        }
    }

    // >>>>Handle Submit Data<<<<
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            workWithUs: workWithUsData,
        }

        // return
        setLoading(true)
        const res = await HttpClient.requestData(`career/${expertID}`, "PUT", data);
        if (res && res?.status) {
            setLoading(false)
            toast.success(res?.message || "Data updated successfully.!");
            setWorkWithUsGetData([workInit])
            setIsEdit(false)
            fetchWWUData()
            document.querySelectorAll('input[type="file"]').forEach(input => input.value = '');
        } else {
            setLoading(false)
            return toast.error(res?.message || "Something went wrong...!")
        }
    }

    useEffect(() => {
        fetchWWUData()
    }, [])

    return (
        <div className="d-flex justify-content-end">
            {loading && <Loader />}
            <div className="form-header mx-2">
                <section className="piechartsBox_area">
                    {/* WorkWithUs */}

                    {isEdit &&
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className='p-2 mb-4' style={{ border: "1px solid #eee" }}>
                                    <div className='d-flex'>
                                        <h2 >Update Work With Us</h2> <span className='mt-2 mx-2' style={{ cursor: "pointer", color: "green" }} onClick={handleAddWWU}><FiPlusCircle /> Add More</span>
                                    </div>
                                    <div style={{ height: "450px", overflow: "auto" }}>
                                        {workWithUsData?.map((ele, index) => (
                                            <div className="form-group section p-2 mt-2" style={{ border: "1px solid #ccc" }}>
                                                <div className="form-group mb-3">
                                                    {index !== -1 && (
                                                        <span

                                                            onClick={() => handleRemoveWWU(index)}
                                                        >
                                                            <TbCircleMinus style={{ cursor: "pointer", color: "red", marginTop: "-3px", marginRight: "2px" }} />
                                                        </span>
                                                    )}
                                                    <label htmlFor="heading2">Heading</label>
                                                    <input type="text" id="heading2" name="heading" className="form-control" value={ele?.heading} onChange={(e) => handleWWUChange(index, 'heading', e.target.value)} />
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label htmlFor="description2">Description</label>
                                                    <textarea id="description2" name="description" className="form-control" rows="3" value={ele?.description} onChange={(e) => handleWWUChange(index, 'description', e.target.value)}></textarea>
                                                </div>

                                                <div className='d-flex'>
                                                    <div className="form-group mb-6 w-50">
                                                        <label htmlFor={`image-${index}`}>Image</label>
                                                        <input
                                                            type="file"
                                                            id={`image-${index}`}
                                                            name="image"
                                                            className="form-control"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageChange(index, e)}
                                                        />
                                                    </div>
                                                    {ele?.image && (
                                                        <div className="mx-5 d-flex align-items-center justify-content-center">
                                                            <img
                                                                className=""
                                                                alt="service"
                                                                width={"100px"}
                                                                height={"100px"}
                                                                src={ele?.image}
                                                                style={{ border: "1px solid #000", borderRadius: "10px", boxShadow: "0 7px 8px rgba(0,0,0,0.5)" }}
                                                            />
                                                            <span
                                                                style={{
                                                                    marginTop: "-4em", fontSize: "20px", cursor: "pointer"
                                                                }}
                                                                onClick={() => handleRemoveImage(index)}
                                                            >
                                                                <i className="fa-solid fa-square-xmark" style={{ color: "#b40822" }}></i>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center mt-2'>
                                    <button type='submit' className='btn btn-outline-success w-50'>Update</button>
                                </div>
                            </form>
                            <hr />
                        </>
                    }

                    {workWithUsGetData && !isEdit && (
                        <div className="mt-5">
                            <h3>Work With Us Data</h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Heading</th>
                                        <th>Description</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workWithUsGetData?.length !== 0 ? (
                                        workWithUsGetData?.map((service, index) => (
                                            <tr key={service._id}>
                                                <td>{service?.heading || '-'}</td>
                                                <td>{service?.description || '-'}</td>
                                                <td>
                                                    {service?.image ? (
                                                        <img
                                                            alt="Service"
                                                            width={"100px"}
                                                            height={"100px"}
                                                            src={service?.image}
                                                            style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                        />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>
                                                <td>
                                                    <FaEye
                                                        style={{ cursor: "pointer", marginRight: "15px", color: "#007bff" }}
                                                        onClick={() => handleViewDetails(service)}
                                                        title="View Details"
                                                    />
                                                    <FaEdit
                                                        style={{ cursor: "pointer", color: "#28a745" }}
                                                        onClick={handleEdit}
                                                        title="Edit"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No services available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}


                    {/* Modal for Viewing Details */}
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Work With Us Details
                            <span style={{ marginLeft: "455px", cursor: "pointer" }} onClick={toggleModal}>
                                <FaTimes className='' style={{ marginTop: "-80px" }} />
                            </span>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ overflow: "auto", height: "300px" }}>
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Heading</td>
                                            <td>{viewData?.heading || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Description</td>
                                            <td>{viewData?.description || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Image</td>
                                            <td>
                                                {viewData?.image ? (
                                                    <img
                                                        alt="Service"
                                                        width={"100px"}
                                                        height={"100px"}
                                                        src={viewData?.image}
                                                        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </Modal>
                </section>
            </div>
        </div>
    )
}

export default WorkWithUs